<template>
  <header class="d-flex justify-content-between align-items-center" style="background-color: white;">
    <label for="toggle-1" class="toggle-menu" @click="toggleMenu"
      ><ul>
        <li style="background-color: #000;"></li>
        <li style="background-color: #000;"></li>
        <li style="background-color: #000;"></li>
        </ul
    ></label>
    <!-- <strong @click="toggleMenu"> &#9776;	</strong> -->
    <nav
      class="nav_center"
      style="padding: 0"
      :style="{ display: isMenuVisible ? 'block' : 'none' }"
    >
        <ul style="list-style: none">
          <li style="border-right: 1px solid rgb(167 167 167);" :class="{ topmenuactive: activeTab === 'thidiem' }" @click="changeTab('thidiem')">
          <a>{{ currentLanguage.header_text_theDaNang_CTThiDiem }}</a>
        </li>
        <li style="border-right: 1px solid rgb(167 167 167);" :class="{ topmenuactive: activeTab === 'taitro' }" @click="changeTab('taitro')">
          <a>{{ currentLanguage.header_text_theDaNang_CTTaiCho }}</a>
        </li>
        <li style="border-right: 1px solid rgb(167 167 167);" :class="{ topmenuactive: activeTab === 'giaoduc' }" @click="changeTab('giaoduc')">
          <a >{{ currentLanguage.header_text_theDaNang_phoCap_bogiaoduc }}</a>
        </li>
        <li style="border-right: 1px solid rgb(167 167 167);" :class="{ topmenuactive: activeTab === 'suckhoe' }" @click="changeTab('suckhoe')">
          <a>{{ currentLanguage.header_text_diemThanhToan_sucKhoe }}</a>
        </li>
        <li style="border-right: 1px solid rgb(167 167 167);" :class="{ topmenuactive: activeTab === 'giaothongvadichvucong' }" @click="changeTab('giaothongvadichvucong')">
          <a>{{ currentLanguage.header_text_diemThanhToan_diChuyen  }} & {{ currentLanguage.header_text_dichVuCong  }}</a>
        </li>
        <li style="border-right: 1px solid rgb(167 167 167);" :class="{ topmenuactive: activeTab === 'huyenngheo' }" @click="changeTab('huyenngheo')">
          <a>{{ currentLanguage.header_text_theDaNang_HuyenNgheo }}</a>
        </li> 
       
        </ul>
      </nav>
      
    </header>
    <div class="wrapper row3">
      <main class="hoc container-fluid clear bv">
      
 <!-- thi diem -->
            <div id="thidiem" v-show="showThiDiem"  style="min-height: 500px"  class="padding-top15"  >
              <div class="title-bai-viet" v-html="titleThiDiem"></div>
              <div v-html="contentThiDiem"></div>
            </div>
  <!-- tai tro-->
            <div id="taitro" v-show="showTaiTro" style="min-height: 500px; background-image: none !important"  class="padding-top15"   >
              <div class="title-bai-viet" v-html="titleTaiTro"></div>
              <div v-html="contentTaiTro"></div>
            </div>
  <!-- Giao Duc -->
            <div id="giaoduc"  v-show="showGiaoDuc"    style="min-height: 500px; background-image: none !important"   class="padding-top15"   >
              <div class="title-bai-viet" v-html="titleGiaoDuc"></div>
              <div v-html="contentGiaoDuc"></div>
            </div>
   <!-- suc khoe -->
            <div id="suckhoe" v-show="showSucKhoe" style="min-height: 500px; background-image: none !important"  class="padding-top15" >
            <div class="title-bai-viet" v-html="titleSucKhoe"></div>
              <div v-html="contentSucKhoe"></div>
          </div>
   <!-- giaothongvadichvucong -->
            <div id="giaothongvadichvucong" v-show="showGiaoThongDVC" style="min-height: 500px; background-image: none !important"  class="padding-top15" >
              <div class="title-bai-viet" v-html="titleGiaoThongDVC"></div>
              <div v-html="contentGiaoThongDVC"></div>
            </div>
   <!-- ngan hang -->
             <div id="huyenngheo" v-show="showHuyenNgheo" style="min-height: 500px; background-image: none !important"    >
                <div class="title-bai-viet" v-html="titleHuyenNgheo"></div>
              <div v-html="contentHuyenNgheo"></div>
            </div>

       
      </main>
    </div>
    <FooterView></FooterView>
  </template>
  <script>
  import FooterView from "@/views/Vimass/Footer/FooterView.vue";
  import { languages } from "@/components/languages";
  import { apiChiTietBaiViet } from "@/components/listService";
  import axios from "axios";
  
  export default {
    components: {
      FooterView,
    },
    data() {
      return {
        activeTab: 'thidiem',
        isMenuVisible: false,
        currentLanguage: languages.vi,
        lang: "",
        base64: "",
        base64Title: "",
  
        //show
        showThiDiem: true,
        showTaiTro: false,
        showGiaoDuc: false,
        showSucKhoe: false,
        showGiaoThongDVC:false,
        showHuyenNgheo:false,
        
        //active tab
        isActiveThiDiem: true,
        isActiveTaiTro: false,
        isActiveGiaoDuc: false,
        isAcitveSucKhoe: false,
        isActiveGiaoThongDVC:false,
        isActiveHuyenNgheo:false,
       
        //title
        titleThiDiem: "",
        titleTaiTro: "",
        titleGiaoDuc: "",
        titleSucKhoe : "",
        titleGiaoThongDVC:"",
        titleHuyenNgheo:"",
  
        //content
        contentThiDiem: "",
        contentTaiTro: "",
        contentGiaoDuc: "",
        contentSucKhoe:"",
        contentGiaoThongDVC:"",
        contentHuyenNgheo:"",
        hasMounted: false
      };
    },
    mounted() {
      if (window.innerWidth <= 768) {
      this.isMenuVisible = false;
    } else {
      this.isMenuVisible = true;
    }
    this.changeTab("thidiem");
},
    created() {
    const savedLanguage = localStorage.getItem("languages");
    if (savedLanguage && languages[savedLanguage]) {
      this.currentLanguage = languages[savedLanguage];
    }
  },
    methods: {
      changeLanguage(lang) {
        this.currentLanguage = languages[lang];
        localStorage.setItem("languages", lang);
         window.location.href = '/' + lang + '/chuongtrinhthidiemvataitro#thidiem/';


      },
      async performPostRequest(id, section) {
        this.lang = localStorage.getItem("languages");
  
        try {
          // Thực hiện POST request
          const response = await axios.post(apiChiTietBaiViet, {
            id: id,
            langId: "0",
          });
          if (this.lang === "vi") {
            this.base64 = response.data.result.content_vi;
            this.base64Title = response.data.result.title_vi;
          } else if (this.lang === "en") {
            this.base64 = response.data.result.content_en;
            this.base64Title = response.data.result.title_en;
          }else if (this.lang === "cn") {
            this.base64 = response.data.result.content_cn;
            this.base64Title = response.data.result.title_cn;
          }else if (this.lang === "kr") {
            this.base64 = response.data.result.content_kr;
            this.base64Title = response.data.result.title_kr;
          }else{
          this.base64 = response.data.result.content_vi;
          this.base64Title = response.data.result.title_vi;
        }
  
          var title = atob(this.base64Title);
          var decodedString = atob(this.base64);
  
          // Store content separately for each section
          if (section === "thidiem") {
            this.titleThiDiem = decodeURIComponent(escape(title));
            this.contentThiDiem = decodeURIComponent(escape(decodedString));
          } else if (section === "taitro") {
            this.titleTaiTro = decodeURIComponent(escape(title));
            this.contentTaiTro = decodeURIComponent(escape(decodedString));
          }else if (section === "giaoduc") {
            this.titleGiaoDuc = decodeURIComponent(escape(title));
            this.contentGiaoDuc = decodeURIComponent(escape(decodedString));
          }else if (section === "suckhoe") {
            this.titleSucKhoe = decodeURIComponent(escape(title));
            this.contentSucKhoe = decodeURIComponent(escape(decodedString));
          }else if (section === "giaothongvadichvucong") {
            this.titleGiaoThongDVC = decodeURIComponent(escape(title));
            this.contentGiaoThongDVC = decodeURIComponent(escape(decodedString));
          }else if (section === "huyenngheo") {
            this.titleHuyenNgheo = decodeURIComponent(escape(title));
            this.contentHuyenNgheo = decodeURIComponent(escape(decodedString));
          }
        } catch (error) {
          console.error("Error:", error);
        }
      },
      toggleMenu() {
      this.isMenuVisible = true;
    },
      changeTab(tab) {
        if (window.innerWidth <= 768) {
        this.isMenuVisible = false;
      }
        this.lang = localStorage.getItem("languages");
  
        if (tab === 'thidiem') {
          this.isActiveThiDiem = true;
          this.isActiveTaiTro = false;
          this.isActiveGiaoDuc = false;
          this.isAcitveSucKhoe = false;
          this.isActiveGiaoThongDVC =false;
          this.isActiveHuyenNgheo=false;
        
          this.performPostRequest("1697101199141EwMrG", 'thidiem', this.lang);
  
        } else if (tab === 'taitro') {
            this.isActiveThiDiem = false;
          this.isActiveTaiTro = true;
          this.isActiveGiaoDuc = false;
          this.isAcitveSucKhoe = false;
          this.isActiveGiaoThongDVC =false;
          this.isActiveHuyenNgheo=false;
          this.performPostRequest("1688091199705sulw5", 'taitro', this.lang);
  
        } else if (tab === 'giaoduc') {
            this.isActiveThiDiem = false;
          this.isActiveTaiTro = false;
          this.isActiveGiaoDuc = true;
          this.isAcitveSucKhoe = false;
          this.isActiveGiaoThongDVC =false;
          this.isActiveHuyenNgheo=false;
          this.performPostRequest("1694406782839x1e10", 'giaoduc', this.lang);
  
        } else if (tab === 'suckhoe') {
            this.isActiveThiDiem = false;
          this.isActiveTaiTro = false;
          this.isActiveGiaoDuc = false;
          this.isAcitveSucKhoe = true;
          this.isActiveGiaoThongDVC =false;
          this.isActiveHuyenNgheo=false;
          this.performPostRequest("16914910788912yc0k", 'suckhoe', this.lang);
  
        }else if (tab === 'giaothongvadichvucong') {
            this.isActiveThiDiem = false;
          this.isActiveTaiTro = false;
          this.isActiveGiaoDuc = false;
          this.isAcitveSucKhoe = false;
          this.isActiveGiaoThongDVC =true;
          this.isActiveHuyenNgheo=false;
          this.performPostRequest("1694503292040xewv1", 'giaothongvadichvucong', this.lang);
        }
      else if (tab === 'huyenngheo') {
        this.isActiveThiDiem = false;
          this.isActiveTaiTro = false;
          this.isActiveGiaoDuc = false;
          this.isAcitveSucKhoe = false;
          this.isActiveGiaoThongDVC =false;
          this.isActiveHuyenNgheo=true;
          this.performPostRequest("1695963969186lClsN", 'huyenngheo', this.lang);
        
        }
  
        this.activeTab = tab; // Cập nhật giá trị activeTab

        this.showThiDiem = tab === 'thidiem';
        this.showTaiTro = tab === 'taitro';
        this.showGiaoDuc = tab === 'giaoduc';
        this.showSucKhoe = tab === 'suckhoe';
        this.showGiaoThongDVC = tab==='giaothongvadichvucong';
        this.showHuyenNgheo = tab == 'huyenngheo';
      },
   
    },
  };
  </script>