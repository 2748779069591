<template>
  <nav class="nav_center2 d-flex justify-content-center w-100 p-0">
    <ul class="ul_menu" style="list-style: none">
      <li
        :class="{ topmenuactive: activeTab === 'ThongTin' }"
        @click="changeTab('ThongTin')"
      >
        <a>{{
          currentLanguage.body_text_ungdung_hethongthongtin
        }}</a>
      </li>
      <li
        :class="{ topmenuactive: activeTab === 'ThanhToan' }"
        @click="changeTab('ThanhToan')"
      >
        <a>{{
          currentLanguage.header_text_thietBi_htThanhToan 
        }} & {{ currentLanguage.header_text_ungDung_vpass }}
        </a>
      </li>
      <li
        :class="{ topmenuactive: activeTab === 'HeThongVPass' }"
        @click="changeTab('HeThongVPass')"
      >
        <a>{{
          currentLanguage.header_text_thietBi_htVpass
        }}</a>
      </li>
      <li
        :class="{ topmenuactive: activeTab === 'HeThongTruSo' }"
        @click="changeTab('HeThongTruSo')"
      >
        <a>{{
          currentLanguage.header_text_HeThongTruSo
        }}</a>
      </li>
      <li
        :class="{ topmenuactive: activeTab === 'HuongDan' }"
        @click="changeTab('HuongDan')"
      >
        <a>{{ currentLanguage.header_text_huongDan }}</a>
      </li>
    </ul>
  </nav>
  <div class="wrapper row3">
    <main class="hoc container-fluid clear bv">
      <div class="wrapper row3">
        <main class="hoc container-fluid clear bv">
          <!-- ThongTin -->
          <div
            id="ThongTin"
            v-show="showThongTin"
            style="min-height: 500px"
            class="padding-top15"
          >
            <div class="title-bai-viet" v-html="titleThongTin"></div>
            <div v-html="contentThongTin"></div>
          </div>
          <!-- ThanhToan -->
          <div
            id="ThanhToan"
            v-show="showThanhToan"
            style="min-height: 500px; background-image: none !important"
            class="padding-top15"
          >
          <div class="title-bai-viet" v-html="titleThanhToan"></div>
            <div v-html="contentThanhToan"></div>
            <!-- <HTThanhToanVue></HTThanhToanVue> -->
          </div>
          <!-- HeThongVPass -->
          <div
            id="HeThongVPass"
            v-show="showHeThongVPass"
            style="min-height: 500px; background-image: none !important"
            class="padding-top15"
          >
            <HeThongVPass></HeThongVPass>
            <div class="title-bai-viet" v-html="titleHeThongVPass"></div>
            <div v-html="contentHeThongVPass"></div>
          </div>
          <!-- HeThongTruSo -->
          <div
            id="HeThongTruSo"
            v-show="showHeThongTruSo"
            style="min-height: 500px; background-image: none !important"
            class="padding-top15"
          >
            <div class="title-bai-viet" v-html="titleHeThongTruSo"></div>
            <div v-html="contentHeThongTruSo"></div>
          </div>
          <div
            id="HuongDan"
            v-show="showHuongDan"
            style="min-height: 500px; background-image: none !important"
            class="padding-top15"
          >
            <div class="title-bai-viet" v-html="titleHuongDan"></div>
            <div v-html="contentHuongDan"></div>
          </div>
        </main>
      </div>
    </main>
  </div>
</template>
  <script>
import { languages } from "@/components/languages";
import { apiChiTietBaiViet } from "@/components/listService";
import axios from "axios";

export default {
  
  data() {
    return {
      activeTab: "ThongTin",

      currentLanguage: languages.vi,
      lang: "",
      base64: "",
      base64Title: "",

      //show
      showThongTin: true,
      showThanhToan: false,
      showHeThongVPass: false,
      showHeThongTruSo: false,
      showHuongDan: false,

      //active tab
      isActiveThongTin: true,
      isActiveThanhToan: false,
      isActiveHeThongVPass: false,
      isAcitveHeThongTruSo: false,
      isActiveHuongDan: false,

      //title
      titleThongTin: "",
      titleThanhToan: "",
      titleHeThongVPass: "",
      titleHeThongTruSo: "",
      titleHuongDan: "",

      //content
      contentThongTin: "",
      contentThanhToan: "",
      contentHeThongVPass: "",
      contentHeThongTruSo: "",
      contentHuongDan: "",
    };
  },
  mounted() {
    this.lang = localStorage.getItem("languages");

    this.performPostRequest("1703131634689t1K2j", "ThongTin", this.lang);
  },
  created() {
    const savedLanguage = localStorage.getItem("languages");
    if (savedLanguage && languages[savedLanguage]) {
      this.currentLanguage = languages[savedLanguage];
    }
  },
  methods: {
    changeLanguage(lang) {
      this.currentLanguage = languages[lang];
      localStorage.setItem("languages", lang);
      window.location.reload();
    },

    async performPostRequest(id, section) {
      this.lang = localStorage.getItem("languages");

      try {
        // Thực hiện POST request
        const response = await axios.post(apiChiTietBaiViet, {
          id: id,
          langId: "0",
        });
        if (this.lang === "vi") {
          this.base64 = response.data.result.content_vi;
          this.base64Title = response.data.result.title_vi;
        } else if (this.lang === "en") {
          this.base64 = response.data.result.content_en;
          this.base64Title = response.data.result.title_en;
        } else if (this.lang === "cn") {
          this.base64 = response.data.result.content_cn;
          this.base64Title = response.data.result.title_cn;
        }

        var title = atob(this.base64Title);
        var decodedString = atob(this.base64);

        // Store content separately for each section
        if (section === "ThongTin") {
          this.titleThongTin = decodeURIComponent(escape(title));
          this.contentThongTin = decodeURIComponent(escape(decodedString));
        } else if (section === "ThanhToan") {
          this.titleThanhToan = decodeURIComponent(escape(title));
          this.contentThanhToan = decodeURIComponent(escape(decodedString));
        } else if (section === "HeThongVPass") {
          this.titleHeThongVPass = decodeURIComponent(escape(title));
          this.contentHeThongVPass = decodeURIComponent(escape(decodedString));
        } else if (section === "HeThongTruSo") {
          this.titleHeThongTruSo = decodeURIComponent(escape(title));
          this.contentHeThongTruSo = decodeURIComponent(escape(decodedString));
        } else if (section === "HuongDan") {
          this.titleHuongDan = decodeURIComponent(escape(title));
          this.contentHuongDan = decodeURIComponent(escape(decodedString));
        }
      } catch (error) {
        console.error("Error:", error);
      }
    },
    changeTab(tab) {
      this.lang = localStorage.getItem("languages");

      if (tab === "ThongTin") {
        this.isActiveThongTin = true;
        this.isActiveThanhToan = false;
        this.isActiveHeThongVPass = false;
        this.isAcitveHeThongTruSo = false;
        this.isActiveHuongDan = false;
        this.performPostRequest("1703131634689t1K2j", "ThanhToan", this.lang);
      } else if (tab === "ThanhToan") {
        this.isActiveThongTin = false;
        this.isActiveThanhToan = true;
        this.isActiveHeThongVPass = false;
        this.isAcitveHeThongTruSo = false;
        this.isActiveHuongDan = false;
        this.performPostRequest("1710304613632p5sAE", "ThanhToan", this.lang);
      } else if (tab === "HeThongVPass") {
        this.isActiveThongTin = false;
        this.isActiveThanhToan = false;
        this.isActiveHeThongVPass = true;
        this.isAcitveHeThongTruSo = false;
        this.isActiveHuongDan = false;
        this.performPostRequest(
          "1696472407793JGiel",
          "HeThongVPass",
          this.lang
        );
      } else if (tab === "HeThongTruSo") {
        this.isActiveThongTin = false;
        this.isActiveThanhToan = false;
        this.isActiveHeThongVPass = false;
        this.isAcitveHeThongTruSo = true;
        this.isActiveHuongDan = false;
        this.performPostRequest(
          "1703131754360FGRAL",
          "HeThongTruSo",
          this.lang
        );
      } else if (tab === "HuongDan") {
        this.isActiveThongTin = false;
        this.isActiveThanhToan = false;
        this.isActiveHeThongVPass = false;
        this.isAcitveHeThongTruSo = false;
        this.isActiveHuongDan = true;
        this.performPostRequest("17014203345248QarX", "HuongDan", this.lang);
      }

      this.activeTab = tab; // Cập nhật giá trị activeTab

      this.showThongTin = tab === "ThongTin";
      this.showThanhToan = tab === "ThanhToan";
      this.showHeThongVPass = tab === "HeThongVPass";
      this.showHeThongTruSo = tab === "HeThongTruSo";
      this.showHuongDan = tab === "HuongDan";
    },
  },
};
</script>
<style scoped>
.ul_menu{
  box-shadow: 0 0 15px #c1ced5; border-radius: 5px; margin-top: 10px; margin-bottom: 10px;
}
.ul_menu li{
  background: white; border-left: 1px solid #2080b4af; color: rgb(92, 92, 145);

}
.ul_menu li:first-child a:hover{
  border-radius: 5px 0 0 5px; color: white
}
.ul_menu li:last-child a:hover{
  border-radius: 0px 5px 5px 0px; color: white
}
.ul_menu li a:hover{
  color: white; background: #4da9e0;
}
.ul_menu li a{
  color: #000;
}
.ul_menu li:first-child{
  border-left: none!important; border-radius: 5px 0 0 5px
}

.ul_menu li:last-child{
  border-radius: 0px 5px 5px 0px;
}
.topmenuactive a{
  color: white!important;;
}
</style>