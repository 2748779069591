import axios from "axios";
import { apiCheckLogin, apiDangKy, apiDangNhap, apiVpos, apiRegisterByEmail, apiSearchBank, apiUploadImg, 
  apiComfrimPhoneOTP, apiChangePassPhone,apiComfrimChangePassPhone, apiCategory} from "./api";

import CryptoJS from "crypto-js";


export const getMD5 = (input) => {
  return CryptoJS.MD5(input).toString();
};
const header = {
  "Access-Control-Allow-Origin": "https://vimass.vn",
  "Access-Control-Allow-Methods": "GET, POST, OPTIONS",
  "Access-Control-Allow-Headers":
    "DNT,X-Mx-ReqToken,Keep-Alive,User-Agent,X-Requested-With,If-Modified-Since,Cache-Control,Content-Type,Range",
  "Access-Control-Allow-Credentials": "true",
  "Access-Control-Max-Age": 3600,
  "Access-Control-Expose-Headers": "Content-Length",
};
const codeCheckSum = "0819226669jdqpowrifioefiqo3289r79f";
const currentTime = new Date().getTime();
const userLapTrinh = "0979951954";
//keyStote
export const API_KEY = 'AIzaSyCj5ljm_ZwVtj7M3MPr7zJSxKO99DQIoV4';
export const KEY_SESSION_ID = localStorage.getItem("sessionUser");
export const KEY_PHONE_ID = localStorage.getItem("phoneUser");
export const KEY_TYGIA = localStorage.getItem("objTyGia");
export const KEY_CHONTYGIA = localStorage.getItem("chonTyGia");
export const KEY_ID_STORE = localStorage.getItem("idCuaHang") ;
export const KEY_STORE_CHUNG = localStorage.getItem("objThongTinCuaHang") ;
//check login
export const getCheckLoginUser = (idVi) => {
  var cks = getMD5(currentTime + "1216546" + idVi + userLapTrinh);
  return axios
    .get(
      apiCheckLogin + currentTime + "_" + cks + "_" + idVi + "_" + userLapTrinh,
      {
        headers: header,
      }
    )
    .then((res) => {
      return res.data;
    });
};
//  dang nhap
export const postLogin = (
  user,
  pass,
  deviceId,
  VimassMH,
  companyCode,
  type
) => {
  return axios
    .post(
      apiDangNhap,
      { user, pass, deviceId, VimassMH, companyCode, type },
      {
        headers: header,
      }
    )
    .then((res) => {
      return res.data;
    });
};
//  dang ky
export const postResgister = (phone, pass) => {
  var funcId = 21;
  var deviceId = 4;
  var appId = 1;
  return axios
    .post(
      apiDangKy,
      { phone, pass, appId, deviceId, funcId },
      {
        headers: header,
      }
    )
    .then((res) => {
      return res.data;
    });
};
//postLayDsStore
export const postLayDsStore = (sessionLogin, idLogin, accOwner) => {
  var funcId = 2;
  var timeRequest = currentTime;
  var companyCode = "";
  var typeLogin = 0;
  var idStore = "";
  var mcId = "";
  var limit = 20;
  var offset = 0;

  var cksInput = getMD5(
    codeCheckSum +
      sessionLogin +
      idLogin +
      typeLogin +
      idStore +
      timeRequest +
      limit +
      offset
  );

  return axios
    .post(
      apiVpos,
      {
        funcId,
        sessionLogin,
        idLogin,
        typeLogin,
        accOwner,
        companyCode,
        idStore,
        mcId,
        timeRequest,
        cksInput,
        limit,
        offset,
      },
      {
        headers: header,
      }
    )
    .then((res) => {
      return res.data;
    });
};

//postLayDsStoreTheoQuyen
export const postLayDsStoreTheoQuyen = (sessionLogin, idLogin, accOwner) => {
  var funcId = 4;
  var timeRequest = currentTime;
  var companyCode = "";
  var typeLogin = 0;
  var idStore = "";
  var mcId = "";
  var limit = 20;
  var offset = 0;
  var cksInput = getMD5(
    codeCheckSum +
      sessionLogin +
      idLogin +
      typeLogin +
      idStore +
      timeRequest
  );
  return axios
    .post(
      apiVpos,
      {
        funcId,
        sessionLogin,
        idLogin,
        typeLogin,
        accOwner,
        companyCode,
        idStore,
        mcId,
        timeRequest,
        cksInput,
        limit,
        offset,
      },
      {
        headers: header,
      }
    )
    .then((res) => {
      return res.data;
    });
};
//postLayDsStoreTheoQuyen
export const postLayDsStoreCongCong = (sessionLogin, idLogin, tuKhoaTimKiem, lat, lng) => {
  var funcId = 8;
  var timeRequest = currentTime;
  var typeLogin = 0;
  var mcId = "";
  var limit = 100;
  var offset = 0;
  var cksInput = getMD5(
    codeCheckSum +
    sessionLogin 
					+ idLogin + typeLogin + timeRequest
					+ tuKhoaTimKiem + lat + lng + offset + limit
  );

  return axios
    .post(
      apiVpos,
      {
        funcId,
        sessionLogin,
        idLogin,
        typeLogin,
        tuKhoaTimKiem,
        lat,
        lng,
        mcId,
        timeRequest,
        cksInput,
        limit,
        offset,
      },
      {
        headers: header,
      }
    )
    .then((res) => {
      return res.data;
    });
};
//searchBank

export const postSearchBank = (
  user,
  session,
  soTaiKhoan,
  maNganHang,
  soThe
) => {
  var appId = 5;
  var VMApp = 4;
  var VimassMH = 1;
 
  return axios
    .post(
      apiSearchBank,
      { user, session, soTaiKhoan, maNganHang, soThe, appId, VMApp, VimassMH },
      {
        headers: header,
      }
    )
    .then((res) => {
      return res.data;
    });
};

export const postImage = (value ,idCheck )=>{
var data = {
  value: value,
  idCheck: idCheck,
}
console.log(data);
return axios.post(apiUploadImg, data, {headers:header}) .then((res) => {
  console.log(res);
  return res.data;
});
}
// tao san pham
export const postProduct = (sessionLogin,idLogin,token,gia,giaGiam,idStore,idCate,productName,image,thumbnail,description) =>{
  var companyCode = "";
  var typeLogin = 0;
  var timeRequest = currentTime;
  var productNameBoDau = removeDiacritics(productName);
var data = {
  funcId:40,
  sessionLogin:sessionLogin,
  idLogin:idLogin,
  token:token,
  typeLogin:typeLogin,
  companyCode:companyCode,
  timeRequest:timeRequest,
  gia:gia,
  giaGiam:giaGiam,
  idStore:idStore,
  idCate:idCate,
  productName:productName,
  image:image,
  thumbnail:thumbnail,
  description:description,
  cksInput:getMD5("0819226669jdqpowrifioefiqo3289r79f" + sessionLogin + idLogin + typeLogin + timeRequest + idStore +idCate + image + productNameBoDau + image + thumbnail + gia + giaGiam)
 
};

console.log("postProduct input: " + JSON.stringify(data));
console.log("0819226669jdqpowrifioefiqo3289r79f" +" "+ sessionLogin +" "+  idLogin +" "+  typeLogin +" "+  timeRequest +" "+  idStore +" "+ idCate +" "+  image +" "+ productNameBoDau +" "+  image +" "+ thumbnail +" "+ gia+" "+ giaGiam);
return axios
.post(apiVpos , data,
  {
    headers: header,
  }
)
.then((res) => {
  return res.data;
});
}
// export const  geocodeAddress =() =>{
//   try {
//     const response =  fetch(
//       `https://maps.googleapis.com/maps/api/js?key=AIzaSyCydVhSpoBMJvAZoVfxOYpWW05e9cLm16E&libraries=places`
//     );
//     const data =  response.json();
    
//     console.log("aaaaaa "+ data);
//     return data;
//     // if (data.results && data.results.length > 0) {
//     //   const location = data.results[0].geometry.location;
//     //   this.latitude = location.lat;
//     //   this.longitude = location.lng;
//     //   return data;
//     // } else {
//     //   alert("Không tìm thấy tọa độ cho địa chỉ này !");
//     // }
//   } catch (error) {
//     console.error("Đã xảy ra lỗi khi lấy tọa độ:", error);
//   }
// }
export const geocodeAddress = () => {
  return new Promise((resolve, reject) => {
    const googleMapsScript = document.createElement("script");
    googleMapsScript.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyCydVhSpoBMJvAZoVfxOYpWW05e9cLm16E&libraries=places`;

    googleMapsScript.onload = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          position => {
            const currentLocation = {
              latitude: position.coords.latitude,
              longitude: position.coords.longitude
            };
            resolve(currentLocation);
          },
          error => {
            console.error("Error getting user's location: ", error);
            reject(error);
          }
        );
      } else {
        console.error("Geolocation is not supported by this browser.");
        reject("Geolocation not supported");
      }
    };

    googleMapsScript.onerror = () => {
      reject("Failed to load Google Maps API");
    };

    window.document.body.appendChild(googleMapsScript);
  });
};

//tao cửa hàng
export const postCreatesStore = (
  sessionLogin,
  idLogin,
  token,
  storeName,
  storeAdd,
  storeCode,
  idParent,
  totalLimit,
  typeWithDrawMoney,
  numberOfTransactions,
  totalTables,
  image,
  emailLH,
  sdtLH,
  bankCode,
  bankNumber,
  lat,
  lng,
  rShowName,
  rMarName,
  rShow,
  rMar, 
  rBillName, 
  rBill, 
  sdtChuCuaHang,
  tienQuyDoiRa1Diem,
  diemQuyDoiRaTien, 
  tienDuocQuyDoi
) => {
  var companyCode = "";
  var typeLogin = 0;
  var mcId = "";
  var timeRequest = currentTime;
  var logo ="";
  const storeNameWithoutDiacritics = removeDiacritics(storeAdd);
  console.log(storeNameWithoutDiacritics);
  var data = {
    funcId: 1,
    sessionLogin: sessionLogin,
    idLogin: idLogin,
    token: token,
    typeLogin: typeLogin,
    companyCode: companyCode,
    storeName: storeName,
    storeAdd: storeAdd,
    storeCode: storeCode,
    idParent: idParent,
    totalLimit: parseInt(totalLimit),
    typeWithDrawMoney: typeWithDrawMoney,
    numberOfTransactions: parseInt(numberOfTransactions),
    totalTables: totalTables,
    logo: logo,
    image: image,
    emailLH: emailLH,
    sdtLH: sdtLH,
    bankCode: bankCode,
    bankNumber: bankNumber,
    lat: lat,
    lng: lng,
    rShowName: rShowName,
    rMarName: rMarName,
    rShow: rShow,
    rMar: rMar,
    mcId: mcId,
    rBillName : rBillName,
    rBill : rBill,
    sdtChuCuaHang : sdtChuCuaHang,
    tienQuyDoiRa1Diem: tienQuyDoiRa1Diem,
    diemQuyDoiRaTien:diemQuyDoiRaTien,
    tienDuocQuyDoi: tienDuocQuyDoi,
    timeRequest: timeRequest,
    cksInput: getMD5(
      "0819226669jdqpowrifioefiqo3289r79f" + sessionLogin + idLogin + typeLogin + companyCode + idParent
      + timeRequest + storeNameWithoutDiacritics + storeCode + logo + image + emailLH + sdtLH + bankCode + bankNumber
      + mcId + rMar + rShow)
  };
console.log("log input ", data);
  return axios
    .post(
      // apiVpos, {data.funcId, data.sessionLogin ,data.idLogin,data.token,data.typeLogin,data.companyCode,data.storeName,data.storeAdd,data.storeCode,data.idParent,data.totalLimitlogo,data.typeWithDrawMoney,
      // data.numberOfTransactions,data.totalTables,data.logo,data.image, data.emailLH,data.bankCode,data.bankNumber,data.lat, data.lng, data.rShowName, data.rMarName, data.mcId, data.timeRequest, data.cksInput}
    apiVpos , data,
      {
        headers: header,
      }
    )
    .then((res) => {
      return res.data;
    });
};

//sửa cửa hàng
export const postUpdateStore = (
  sessionLogin,
  idStore,
  idLogin,
  token,
  storeName,
  storeAdd,
  storeCode,
  idParent,
  typeWithDrawMoney,
  numberOfTransactions,
  image,
  emailLH,
  sdtLH,
  bankCode,
  bankNumber,
  lat,
  lng,
  rShowName,
  rMarName,
  rShow,
  rMar, 
  rBillName, 
  rBill, 
  sdtChuCuaHang,
  tienQuyDoiRa1Diem,
  diemQuyDoiRaTien, 
  tienDuocQuyDoi,
  totalLimit
) => {
  var companyCode = "";
  var typeLogin = 0;
  var mcId = "";
  var timeRequest = currentTime;
  var logo ="";
  var trangThai = 1;
  // const storeNameWithoutDiacritics = removeDiacritics(storeAdd);
  console.log("image" + image);
 
  var data = {
    funcId: 3,
    idStore: idStore,
    sessionLogin: sessionLogin,
    idLogin: idLogin,
    token: token,
    typeLogin: typeLogin,
    companyCode: companyCode,
    storeName: storeName,
    storeAdd: storeAdd,
    storeCode: storeCode,
    storeCodeParent:"",
    idParent: idParent,
    level:0,
    totalLimit:parseInt(totalLimit),
    typeWithDrawMoney: typeWithDrawMoney,
    numberOfTransactions: parseInt(numberOfTransactions),
    totalTables: 0,
    logo: logo,
    image: image,
    emailLH: emailLH,
    sdtLH: sdtLH,
    bankCode: bankCode,
    bankNumber: bankNumber,
    lat: lat,
    lng: lng,
    rShowName: rShowName,
    rMarName: rMarName,
    rShow: rShow,
    rMar: rMar,
    mcId: mcId,
    rBillName : rBillName,
    rBill : rBill,
    trangThai:trangThai,
    coVanChuyen:1,
    sdtChuCuaHang : sdtChuCuaHang,
    tienQuyDoiRa1Diem: tienQuyDoiRa1Diem,
    diemQuyDoiRaTien:diemQuyDoiRaTien,
    tienDuocQuyDoi: tienDuocQuyDoi,
    timeRequest: timeRequest,
    cksInput: getMD5(
      "0819226669jdqpowrifioefiqo3289r79f" + sessionLogin + idLogin + typeLogin
					+ idParent + timeRequest + idStore + trangThai + logo + image + emailLH + sdtLH + bankCode + bankNumber
					+ mcId + rMar + rShow)
  };
console.log("log input ", JSON.stringify(data));
console.log("log input cks",  "0819226669jdqpowrifioefiqo3289r79f" +" " +sessionLogin +" " + idLogin +" " + typeLogin
+" " + idParent +" " + timeRequest+" " + idStore +" " + trangThai +" " + logo +" " + image +" " + emailLH +" " +sdtLH +" " + bankCode +" " + bankNumber
+" " + mcId+" " + rMar+" " +rShow);
  return axios
    .post(
      // apiVpos, {data.funcId, data.sessionLogin ,data.idLogin,data.token,data.typeLogin,data.companyCode,data.storeName,data.storeAdd,data.storeCode,data.idParent,data.totalLimitlogo,data.typeWithDrawMoney,
      // data.numberOfTransactions,data.totalTables,data.logo,data.image, data.emailLH,data.bankCode,data.bankNumber,data.lat, data.lng, data.rShowName, data.rMarName, data.mcId, data.timeRequest, data.cksInput}
    apiVpos , data,
      {
        headers: header,
      }
    )
    .then((res) => {
      if (res.data.msgCode == 1 && res.data.msgCode != null) {
      
        localStorage.setItem("selectedStore", JSON.stringify(data));
  return res.data;
      } else {
        return res.data;
      }
    });
};
 
//tra cứu điểm thưởng
export const postTraCuuDiemThuong=(sessionLogin, idLogin, accOwner, userDiem, idStore) =>{
  var funcId = 6;
  var companyCode = "";

var data = {
  funcId: funcId,
  sessionLogin: sessionLogin, 
  idLogin : idLogin, 
  accOwner : accOwner, 
  companyCode : companyCode,
  idStore: idStore,
  userDiem: userDiem,
  timeRequest : currentTime, 
  cksInput : getMD5("0819226669jdqpowrifioefiqo3289r79f" + sessionLogin + idLogin + currentTime + idStore + userDiem)

}
return axios
.post(apiVpos, data,
{
  headers: header,
})
.then((res) => {
  return res.data;
});
}


//  dang ky email
export const postResgisterByEmail = (email, pass , ipWan) => {
  var funcId = 190;
  var ip = ipWan;
  var timeRequest = currentTime;
  var userLTV = "0981455707";
  var VMApp = 4;
  var deviceId = 2;
  var passL = btoa(pass);

  var keyTheoVMApp = "Y4SkpSofFAgsR27fMBM02SNoV9iPI2CBo0ZCImM6EgnfXikl3en3VfWyTwfLLTC883yJC";
  var cksInput = getMD5(keyTheoVMApp + userLTV + email + timeRequest + ip + deviceId + passL);

  var data = {funcId,ip,timeRequest,userLTV,VMApp,deviceId,email,passL,cksInput};
  console.error('postResgisterByEmail =========>'+JSON.stringify(data));
  return axios
    .post(apiRegisterByEmail, data,
    {
      headers: header,
    })
    .then((res) => {
      return res.data;
    });
};

export const getIpWan = () => {
  return axios
    .get('https://api.ipify.org?format=json')
    .then((res) => {
      return res.data.ip; 
    })
    .catch((error) => {
      console.error('Error fetching IP address:', error);
      return null;
    });
};

//  comfrim code OTP 
export const postComfrimOTP = (codeOTP, ipWan, ConfirmOTP) => {
  var funcId = 191;
  var ip = ipWan;
  var timeRequest = currentTime;
  var userLTV = "0981455707";
  var VMApp = 4;
  var deviceId = 2;
  var idConfirm = codeOTP;
  var otpConfirm = ConfirmOTP;
  var keyTheoVMApp = "Y4SkpSofFAgsR27fMBM02SNoV9iPI2CBo0ZCImM6EgnfXikl3en3VfWyTwfLLTC883yJC";
  var cksInput = getMD5(keyTheoVMApp + userLTV + idConfirm + timeRequest + ip + deviceId + otpConfirm);

  var data = {funcId,ip,timeRequest,userLTV,VMApp,deviceId,idConfirm,otpConfirm,cksInput};
  console.error('postComfrimOTP =========>'+JSON.stringify(data));
  return axios
    .post(apiRegisterByEmail, data,
    {
      headers: header,
    })
    .then((res) => {
      return res.data;
    });
};

//  login by email
export const postLoginByEmail = (emailInput, ipWan, pass) => {
  var funcId = 192;
  var ip = ipWan;
  var timeRequest = currentTime;
  var userLTV = "0981455707";
  var VMApp = 4;
  var deviceId = 2;
  var email = emailInput;
  var passLogin = pass;
  var keyTheoVMApp = "Y4SkpSofFAgsR27fMBM02SNoV9iPI2CBo0ZCImM6EgnfXikl3en3VfWyTwfLLTC883yJC";
  var cksPassLogin = getMD5(keyTheoVMApp + passLogin + timeRequest);
  var cksInput = getMD5(keyTheoVMApp + userLTV + email + timeRequest + ip + deviceId + cksPassLogin);
  
  var data = {funcId,ip,timeRequest,userLTV,VMApp,deviceId,email,cksPassLogin,cksInput};
  console.error('postComfrimOTP =========>'+JSON.stringify(data));
  return axios
    .post(apiRegisterByEmail, data,
    {
      headers: header,
    })
    .then((res) => {
      return res.data;
    });
};


export const convertToBase64 = (thumbnail) => {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.onload = function() {
      const reader = new FileReader();
      reader.onloadend = function() {
        resolve(reader.result.split(',')[1]);
      };
      reader.readAsDataURL(xhr.response);
    };
    xhr.onerror = reject;
    xhr.open('GET', thumbnail);
    xhr.responseType = 'blob';
    xhr.send();
  });
}

//  comfrim code OTP 
export const postComfrimPhoneOTP = (phoneComfrim, phoneOTP) => {
  var funcId = 64;
  var phone = phoneComfrim;
  var otp = phoneOTP;
  

  var data = {funcId,phone,otp};
  console.error('postComfrimOTP =========>'+JSON.stringify(data));
  return axios
    .post(apiComfrimPhoneOTP, data,{
      headers: header,
    }).then((res) => {
      return res.data;
    });
};


//  change pass phone
export const getChnagePassPhone = (phoneComfrim, phoneOTP) => {
  var phone = phoneComfrim;
  var par = phoneOTP;
  const url = apiChangePassPhone+"?phone="+phone+"&par="+par;
 // console.log("url ======> " +url);
  return axios
    .get(url ,{
        headers: header,
      }).then((res) => {
      return res.data;
    });
};

//  comfrim change pass phone
export const comfrimGetChnagePassPhone = (phoneChange, codeOTP, parNew) => {
  var phone = phoneChange;
  var otp = codeOTP;
  var par = parNew;
  const url = apiComfrimChangePassPhone+"?phone="+phone+"&otp="+otp+"&par="+par;
  console.log("url ======> " +url);
  return axios
    .get(url ,{
        headers: header,
      }).then((res) => {
      return res.data;
    });
};
// Tạo thông tin 1 category
export const postCreateCategory = (inputToken, InputIdStore, inputCateName) => {
  var funcId = 20;
  var sessionLogin = KEY_SESSION_ID;
	var idLogin = KEY_PHONE_ID;
	var token = inputToken;
	var typeLogin = 0; //0 - vis sdt/email; 1- the da nang; 2 la vi doanh nghiep
	var companyCode =""; // dang vang vi doanh nghiep thi moi truyen
	var idStore = InputIdStore;
	var catName = inputCateName;
	var image = "";
	var timeRequest = new Date().getTime();
	var description = "";



  var cksInput = getMD5("0819226669jdqpowrifioefiqo3289r79f" + sessionLogin + idLogin + typeLogin + timeRequest + idStore + image + removeDiacritics(catName));
  

  var data = {funcId,sessionLogin,idLogin,token,typeLogin,companyCode,idStore,catName,image,timeRequest,description,cksInput };
  // console.error('postCreateCategory =========>'+"0819226669jdqpowrifioefiqo3289r79f" + sessionLogin + idLogin + typeLogin + timeRequest + idStore + image + removeDiacritics(catName));
  return axios
    .post(apiCategory, data,{
      headers: header,
    }).then((res) => {
      return res.data;
    });
};

export const removeVietnameseDiacritics = (str) =>{

  return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
}

export const postReceiveListCategory = (inputIdStore, inputOffset ,inputLimit) => {
  console.log('postReceiveListCategory =========> nhay vao day');
  var funcId = 22;
  var sessionLogin = KEY_SESSION_ID;
	var idLogin = KEY_PHONE_ID;
	var typeLogin = 0; //0 - vis sdt/email; 1- the da nang; 2 la vi doanh nghiep
	var companyCode =""; // dang vang vi doanh nghiep thi moi truyen
	var idStore = inputIdStore;
	var timeRequest = new Date().getTime();
  var offset = inputOffset;
  var limit = inputLimit;

  var cksInput = getMD5("0819226669jdqpowrifioefiqo3289r79f" + sessionLogin + idLogin + typeLogin + timeRequest + idStore + offset + limit);
  var data = {funcId,sessionLogin,idLogin,typeLogin,companyCode,idStore,timeRequest,offset,limit,cksInput};

  //  console.log('postReceiveListCategory =========>'+JSON.stringify(data));
   return axios
   .post(apiCategory, data,{
     headers: header,
   }).then((res) => {
     return res.data;
   });
};

export const postEditCategory = (inputToken, inputIdStore, inputIdCat ,inputCatName, status) => {
  console.log('postReceiveListCategory =========> nhay vao day');
  var funcId = 21;
  var sessionLogin = KEY_SESSION_ID;
	var idLogin = KEY_PHONE_ID;
  var token = inputToken;
	var typeLogin = 0; //0 - vis sdt/email; 1- the da nang; 2 la vi doanh nghiep
	var companyCode =""; // dang vang vi doanh nghiep thi moi truyen
	var idCat = inputIdCat; 
	var idStore = inputIdStore;
	var catName = inputCatName;
	
	var image = "";
	var timeRequest = new Date().getTime();
	var description = "";

	var trangThai = status;
  
  var cksInput = getMD5("0819226669jdqpowrifioefiqo3289r79f" + sessionLogin + idLogin + typeLogin + timeRequest + idStore + image + removeDiacritics(catName) + idCat + trangThai);
 
  var data = {funcId,sessionLogin,idLogin,token,typeLogin,companyCode,idCat,idStore,catName,image,timeRequest,description,trangThai,cksInput};

  //  console.log('postEditCategory =========>'+JSON.stringify(data));
   return axios
   .post(apiCategory, data,{
     headers: header,
   }).then((res) => {
     return res.data;
   });
};

// edit product

export const postEditProduct = (inputToken, itemProductEdit , status) => {
  console.log('postEditProduct =========> nhay vao day');
  var funcId = 41;
	var sessionLogin = KEY_SESSION_ID;
	var idLogin = KEY_PHONE_ID;
	var token = inputToken;
	var typeLogin = 0; //0 - vis sdt/email; 1- the da nang; 2 la vi doanh nghiep
	var companyCode = "";
	var timeRequest = new Date().getTime();
	var idStore = itemProductEdit.idStore;
	var idCate = itemProductEdit.idCate;
	var productName = itemProductEdit.productName;
	var gia = itemProductEdit.gia; 
	var giaGiam = itemProductEdit.giaGiam;
	var image = itemProductEdit.image;
	var thumbnail = itemProductEdit.thumbnail;
	var description = itemProductEdit.description;
	var productId = itemProductEdit.id;
	var trangThai = status;
  // console.log('image =========>'+ image);
  //console.log('postEditProduct cks =========>'+("0819226669jdqpowrifioefiqo3289r79f" + sessionLogin + idLogin + typeLogin + timeRequest + idStore +idCate + image + removeVietnameseDiacritics(productName) + image + thumbnail + productId + trangThai + gia + giaGiam));
  var cksInput = getMD5("0819226669jdqpowrifioefiqo3289r79f" + sessionLogin + idLogin + typeLogin + timeRequest + idStore +idCate + image + removeDiacritics(productName) + image + thumbnail + productId + trangThai + gia + giaGiam);
  var data = {funcId, sessionLogin, idLogin, token, typeLogin, companyCode,timeRequest, idStore, idCate, productName, gia, giaGiam, image, thumbnail, description, productId, trangThai,cksInput};
  //  console.log('postEditCategory =========>'+JSON.stringify(data));
   return axios
   .post(apiCategory, data,{
     headers: header,
   }).then((res) => {
     return res.data;
   });
};

// edit product

export const postDeleteProduct = (inputToken, itemProduct) => {
  console.log('postDeleteProduct =========> nhay vao day');
  var funcId = 42;
	var sessionLogin = KEY_SESSION_ID;
	var idLogin = KEY_PHONE_ID;
	var token = inputToken;
	var typeLogin = 0; //0 - vis sdt/email; 1- the da nang; 2 la vi doanh nghiep
	var companyCode = "";
	var timeRequest = new Date().getTime();
	var idStore = itemProduct.idStore;
	var productId = itemProduct.id;
 // console.log('postDeleteProduct cks =========>'+("0819226669jdqpowrifioefiqo3289r79f" + sessionLogin + idLogin + typeLogin + timeRequest + idStore + productId));
  var cksInput = getMD5("0819226669jdqpowrifioefiqo3289r79f" + sessionLogin + idLogin + typeLogin + timeRequest + idStore + productId);
  var data = {funcId, sessionLogin, idLogin, token, typeLogin, companyCode,timeRequest, idStore, productId, cksInput};
   //console.log('postDeleteProduct =========>'+JSON.stringify(data));
   return axios
   .post(apiCategory, data,{
     headers: header,
   }).then((res) => {
     return res.data;
   });
};

export const  removeDiacritics = (string) => {
  const diacriticsMap = {
    'á': 'a', 'à': 'a', 'ả': 'a', 'ã': 'a', 'ạ': 'a',
    'ă': 'a', 'ắ': 'a', 'ằ': 'a', 'ẳ': 'a', 'ẵ': 'a', 'ặ': 'a',
    'â': 'a', 'ấ': 'a', 'ầ': 'a', 'ẩ': 'a', 'ẫ': 'a', 'ậ': 'a',
    'é': 'e', 'è': 'e', 'ẻ': 'e', 'ẽ': 'e', 'ẹ': 'e',
    'ê': 'e', 'ế': 'e', 'ề': 'e', 'ể': 'e', 'ễ': 'e', 'ệ': 'e',
    'í': 'i', 'ì': 'i', 'ỉ': 'i', 'ĩ': 'i', 'ị': 'i',
    'ó': 'o', 'ò': 'o', 'ỏ': 'o', 'õ': 'o', 'ọ': 'o',
    'ô': 'o', 'ố': 'o', 'ồ': 'o', 'ổ': 'o', 'ỗ': 'o', 'ộ': 'o',
    'ơ': 'o', 'ớ': 'o', 'ờ': 'o', 'ở': 'o', 'ỡ': 'o', 'ợ': 'o',
    'ú': 'u', 'ù': 'u', 'ủ': 'u', 'ũ': 'u', 'ụ': 'u',
    'ư': 'u', 'ứ': 'u', 'ừ': 'u', 'ử': 'u', 'ữ': 'u', 'ự': 'u',
    'ý': 'y', 'ỳ': 'y', 'ỷ': 'y', 'ỹ': 'y', 'ỵ': 'y',
    'đ': 'd',
    'Á': 'A', 'À': 'A', 'Ả': 'A', 'Ã': 'A', 'Ạ': 'A',
    'Ă': 'A', 'Ắ': 'A', 'Ằ': 'A', 'Ẳ': 'A', 'Ẵ': 'A', 'Ặ': 'A',
    'Â': 'A', 'Ấ': 'A', 'Ầ': 'A', 'Ẩ': 'A', 'Ẫ': 'A', 'Ậ': 'A',
    'É': 'E', 'È': 'E', 'Ẻ': 'E', 'Ẽ': 'E', 'Ẹ': 'E',
    'Ê': 'E', 'Ế': 'E', 'Ề': 'E', 'Ể': 'E', 'Ễ': 'E', 'Ệ': 'E',
    'Í': 'I', 'Ì': 'I', 'Ỉ': 'I', 'Ĩ': 'I', 'Ị': 'I',
    'Ó': 'O', 'Ò': 'O', 'Ỏ': 'O', 'Õ': 'O', 'Ọ': 'O',
    'Ô': 'O', 'Ố': 'O', 'Ồ': 'O', 'Ổ': 'O', 'Ỗ': 'O', 'Ộ': 'O',
    'Ơ': 'O', 'Ớ': 'O', 'Ờ': 'O', 'Ở': 'O', 'Ỡ': 'O', 'Ợ': 'O',
    'Ú': 'U', 'Ù': 'U', 'Ủ': 'U', 'Ũ': 'U', 'Ụ': 'U',
    'Ư': 'U', 'Ứ': 'U', 'Ừ': 'U', 'Ử': 'U', 'Ữ': 'U', 'Ự': 'U',
    'Ý': 'Y', 'Ỳ': 'Y', 'Ỷ': 'Y', 'Ỹ': 'Y', 'Ỵ': 'Y',
    'Đ': 'D'
  }

  return string.replace(/[^A-Za-z0-9\s]/g, c => diacriticsMap[c] || c);
}
// sao ke giao dich

export const postSaoKeGiaoDich = (inputType, itemStore, inputTimeFrom, inputTimeTo) => {
  console.log('postDeleteProduct =========> nhay vao day');
  var funcId = 64;
	var sessionLogin = KEY_SESSION_ID;
	var idLogin = KEY_PHONE_ID;
	var typeLogin = 0; //0 - vis sdt/email; 1- the da nang; 2 la vi doanh nghiep
	var companyCode = "";

  var idSaoKe = "";
	var owner = "";
	var maQR = "";
	var billId = "";
	var idStore = itemStore.id;
	var typeSK = inputType;//0 tất cả; 1 - thu; 2 - chi

	var timeFrom = inputTimeFrom;
	var timeTo = inputTimeTo;
	var offset = 0;
	var limit = 100;

	var timeRequest = new Date().getTime();

  // console.log('postSaoKeGiaoDich cks =========>'+("0819226669jdqpowrifioefiqo3289r79f" + sessionLogin + idLogin + typeLogin + timeRequest 
	// 				+ maQR + billId + idStore + owner + idStore + typeSK + timeFrom + timeTo + offset + limit  + idSaoKe));
  var cksInput = getMD5("0819226669jdqpowrifioefiqo3289r79f" + sessionLogin + idLogin + typeLogin + timeRequest 
                  + maQR + billId + idStore + owner + idStore + typeSK + timeFrom + timeTo + offset + limit  + idSaoKe);
  
  var data = {funcId, sessionLogin, idLogin, typeLogin, companyCode, idSaoKe,owner, maQR, billId, idStore, typeSK, 
              timeFrom, timeTo, offset, limit, timeRequest, cksInput};
  //  console.log('postDeleteProduct =========>'+JSON.stringify(data));
   return axios
   .post(apiCategory, data,{
     headers: header,
   }).then((res) => {
     return res.data;
   });
};

//post Lấy Item
export const postLayItem = (idStore, idCate) => {
  
  var funcId = 43;
  var sessionLogin = KEY_SESSION_ID;
	var idLogin = KEY_PHONE_ID;
  var typeLogin = 0;
  var companyCode = "";
  var timeRequest = currentTime;
  var offset = 0;
  var limit = 1000;
  var productId = "";

  var cksInput = getMD5( codeCheckSum + sessionLogin + idLogin + typeLogin +timeRequest +idStore +productId +offset +limit +idCate);
  return axios
    .post(
      apiVpos,
      { funcId,sessionLogin,idLogin, typeLogin,companyCode,timeRequest,idCate, idStore, productId,offset,limit,cksInput
      },
      { header: header }
    )

    .then((res) => {
      return res.data;
    });
};