<template>
  <div class="d-flex justify-content-between">
    <a @click="plusSlides(-1)" style="font-size: x-large">❮</a>
    <div class="d-flex justify-content-between align-items-center">
      <span class="d-flex span-style span-color--black me-1">
        {{ currentLanguage.title_trendienthoai }}</span
      >
    </div>
    <a @click="plusSlides(1)" style="font-size: x-large">❯</a>
  </div>
  <div class="slideshow-container">
    <div
      v-for="(slide, index) in slidePhone"
      :key="index"
      class="mySlides container-fluid"
    >
      <div class="row p-0 h-check">
        <div class="column col-12 col-md-12 p-0 mt-5">
          <div
            class="d-flex justify-content-around pt-2"
            v-if="savedLanguage === 'vi' || !savedLanguage"
          >
            <div class="col-6 col-md-5 p-0 me-3">
              <img
                :src="slide.imageVpos"
                class="hover-shadow cursor img-sile"
              />
            </div>
            <div class="col-6 col-md-5 p-0 d-flex align-items-center">
              <div v-html="slide.htmlImageVpos"></div>
            </div>
          </div>

          <div
            class="d-flex justify-content-center"
            v-if="savedLanguage == 'en' || savedLanguage == 'cn'"
          >
            <div class="col-6 col-md-5 p-0">
              <img
                :src="slide.imageVpos"
                class="hover-shadow cursor img-sile"
              />
            </div>
            <div
              class="col-6 col-md-5 p-0 align-items-center justify-content-center d-flex"
            >
              <div v-html="slide.htmlImageVpos"></div>
            </div>
          </div>
        </div>
        <div class="column col-12 col-md-12 p-0 mt-3" style="height: 120px">
          <!-- <div class="container-fluid " style="height: 110px"> -->
          <div class="d-flex">
            <div v-if="savedLanguage === 'vi' || !savedLanguage">
              <div
             
                class="d-flex mt-3 style-line container-fluid"
              >
            <div class="row"> 
              <div class="col-12 col-md-6"    v-html="slide.htmlContentVpos1"> </div>
              <div class="col-12 col-md-6"    v-html="slide.htmlContentVpos2"> </div>
            </div>
            </div>
            </div>
            <div v-if="savedLanguage == 'en'">
              <div
                v-html="slide.htmlContentVpos1"
                class="d-flex mt-3 style-line"
              ></div>
            </div>
            <div v-if="savedLanguage == 'cn'">
              <div
                v-html="slide.htmlContentVpos1"
                class="d-flex mt-3 style-line"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- <a class="prev" @click="plusSlides(-1)">❮</a>
            <a class="next" @click="plusSlides(1)">❯</a> -->
  </div>
</template>
  <script>
import { languages } from "@/components/js/langVpos";
import { imageUrls, langBanner } from "@/components/js/urlImg";
import axios from "axios";

export default {
  data() {
    return {
      imageUrls: imageUrls,
      currentLanguage: languages.vi,
      langBanner: langBanner.vi,
      slideIndex: 0,
      slides: [
        {
          image: langBanner.vi.img1,
          // image1: langBanner.vi.img2,
          image1:
            "<div class='d-flex flex-column'>" +
            "<span class='fw-bold fs-4'>" +
            languages.vi.titleTaiUngDung +
            "</span>" +
            "<div> <img src='" +
            imageUrls.qrVpos +
            "' class='hover-shadow cursor img-download--qr' /></div>" +
            "<a href='https://apps.apple.com/vn/developer/vimass-corp/id824526949?l=vi' target='_blank'> <img src='" +
            imageUrls.downloadIos +
            "' class='cursor img-download'/></a>" +
            "<a href='https://play.google.com/store/apps/details?id=com.vimass.vpos'target='_blank'> <img src='" +
            imageUrls.downloadAndroid +
            "' class='cursor img-download'/></a>" +
            "</div>",

          imageEn: langBanner.en.img1,
          image1En:
            "<div class='d-flex flex-column'>" +
            "<span class='fw-bold fs-4'>" +
            languages.en.titleTaiUngDung +
            "</span>" +
            "<div> <img src='" +
            imageUrls.qrVpos +
            "' class='hover-shadow cursor img-download--qr' /></div>" +
            "<a href ='https://apps.apple.com/vn/developer/vimass-corp/id824526949?l=vi' target='_blank'> <img src='" +
            imageUrls.downloadIos +
            "' class='cursor img-download'/></a>" +
            "<a href ='https://play.google.com/store/apps/details?id=com.vimass.vpos' target='_blank'> <img src='" +
            imageUrls.downloadAndroid +
            "' class='cursor img-download'/></a>" +
            "</div>",
          tittle: languages.vi.titleDangKy,
          tittEn: languages.en.titleDangKy,
          content: languages.vi.contentDangKyPhone,
          contentEn: languages.en.contentDangKyPhone,
          htmlContent:
            "<div class='container-fluid p-0'> " +
            "<div class='row p-0'> " +
            "  <div class='col-12 col-md-6 p-0'>" +
            "   <span style='left: 0px' >  <span style='font-weight: 600;'>" +
            languages.vi.titleDangKy +
            ":</span> " +
            languages.vi.contentDangKyPhone +
            "</span >" +
            "  </div>" +
            "  <div class='col-12 col-md-6 p-0'>" +
            "  <span style='left: 0px'    >  <span style='font-weight: 600;'>" +
            languages.vi.titleDangNhap +
            ":</span>" +
            languages.vi.contentDangNhapPhone +
            "</span>" +
            "  </div>" +
            "  </div>" +
            "  </div>" +
            "</div>",
          htmlContentEn:
            "<div class='container-fluid p-0'> " +
            "<div class='row'> " +
            "  <div class='col-12 col-md-6 p-0'>" +
            "   <span style='left: 0px' >  <span style='font-weight: 600;'>" +
            languages.en.titleDangKy +
            ":</span> " +
            languages.en.contentDangKyPhone +
            "</span >" +
            "  </div>" +
            "  <div class='col-12 col-md-6 p-0'>" +
            "  <span style='left: 0px'    >  <span style='font-weight: 600;'>" +
            languages.en.titleDangNhap +
            ":</span>" +
            languages.en.contentDangNhapPhone +
            "</span>" +
            "  </div>" +
            "  </div>" +
            "  </div>" +
            "</div>",
          htmlContentCn:
            "<div class='container-fluid p-0'> " +
            "<div class='row'> " +
            "  <div class='col-12 col-md-6 p-0'>" +
            "   <span style='left: 0px' >  <span style='font-weight: 600;'>" +
            languages.cn.titleDangKy +
            ":</span> " +
            languages.cn.contentDangKyPhone +
            "</span >" +
            "  </div>" +
            "  <div class='col-12 col-md-6 p-0'>" +
            "  <span style='left: 0px'    >  <span style='font-weight: 600;'>" +
            languages.cn.titleDangNhap +
            ":</span>" +
            languages.cn.contentDangNhapPhone +
            "</span>" +
            "  </div>" +
            "  </div>" +
            "  </div>" +
            "</div>",
        },
        {
          image: langBanner.vi.img2_1,
          image1:
            "<img src=" +
            langBanner.vi.img2 +
            " class='hover-shadow cursor img-sile'/>",

          imageEn: langBanner.en.img2_1,
          image1En: langBanner.en.img2,
          tittle: languages.vi.titleDangKy,
          tittEn: languages.en.titleDangKy,
          content: languages.vi.contentDangKyPhone,
          contentEn: languages.en.contentDangKyPhone,
          htmlContent:
            "<div class='container-fluid p-0'> " +
            "<div class='row p-0'> " +
            "  <div class='col-12 col-md-6 p-0'>" +
            "   <span style='left: 0px' >  <span style='font-weight: 600;'>" +
            languages.vi.titleDangKy +
            ":</span> " +
            languages.vi.contentDangKyPhone +
            "</span >" +
            "  </div>" +
            "  <div class='col-12 col-md-6 p-0'>" +
            "  <span style='left: 0px'    >  <span style='font-weight: 600;'>" +
            languages.vi.titleDangNhap +
            ":</span>" +
            languages.vi.contentDangNhapPhone +
            "</span>" +
            "  </div>" +
            "  </div>" +
            "  </div>" +
            "</div>",
          htmlContentEn:
            "<div class='container-fluid p-0'> " +
            "<div class='row'> " +
            "  <div class='col-12 col-md-6 p-0'>" +
            "   <span style='left: 0px' >  <span style='font-weight: 600;'>" +
            languages.en.titleDangKy +
            ":</span> " +
            languages.en.contentDangKyPhone +
            "</span >" +
            "  </div>" +
            "  <div class='col-12 col-md-6 p-0'>" +
            "  <span style='left: 0px'    >  <span style='font-weight: 600;'>" +
            languages.en.titleDangNhap +
            ":</span>" +
            languages.en.contentDangNhapPhone +
            "</span>" +
            "  </div>" +
            "  </div>" +
            "  </div>" +
            "</div>",
          htmlContentCn:
            "<div class='container-fluid p-0'> " +
            "<div class='row'> " +
            "  <div class='col-12 col-md-6 p-0'>" +
            "   <span style='left: 0px' >  <span style='font-weight: 600;'>" +
            languages.cn.titleDangKy +
            ":</span> " +
            languages.cn.contentDangKyPhone +
            "</span >" +
            "  </div>" +
            "  <div class='col-12 col-md-6 p-0'>" +
            "  <span style='left: 0px'    >  <span style='font-weight: 600;'>" +
            languages.cn.titleDangNhap +
            ":</span>" +
            languages.cn.contentDangNhapPhone +
            "</span>" +
            "  </div>" +
            "  </div>" +
            "  </div>" +
            "</div>",
        },
        {
          image: langBanner.vi.img3,
          image1:
            "<img src=" +
            langBanner.vi.img3_1 +
            " class='hover-shadow cursor img-sile'/>",
          imageEn: langBanner.en.img3,
          image1En:
            "<img src=" +
            langBanner.en.img2 +
            " class='hover-shadow cursor img-sile'/>",
          tittle: "",
          htmlContent:
            "  <span style='left: 0px' >  <span style='font-weight: 600;'>" +
            languages.vi.titleTaiKhoan +
            ": </span>" +
            languages.vi.contentTaiKhoanPhone +
            "</span>",
          htmlContentEn:
            "  <span style='left: 0px' >  <span style='font-weight: 600;'>" +
            languages.en.titleTaiKhoan +
            ": </span>" +
            languages.en.contentTaiKhoanPhone +
            "</span>",
          htmlContentCn:
            "  <span style='left: 0px' >  <span style='font-weight: 600;'>" +
            languages.cn.titleTaiKhoan +
            ": </span>" +
            languages.cn.contentTaiKhoanPhone +
            "</span>",
        },

        {
          image: langBanner.vi.img4,
          image1:
            "<img src=" +
            langBanner.vi.img5 +
            " class='hover-shadow cursor img-sile'/>",
          imageEn: langBanner.en.img4,
          image1En:
            "<img src=" +
            langBanner.en.img5 +
            " class='hover-shadow cursor img-sile'/>",
          tittle: "",
          htmlContent:
            "<div class='container-fluid'> " +
            "<div class='row'> " +
            "  <div class='col-12 col-md-6'>" +
            "   <span style='left: 0px' >  <span style='font-weight: 600;'>" +
            languages.vi.titleTaoCuaHang +
            ":</span>" +
            languages.vi.contentTaoCuaHangPhone +
            "</span >" +
            "  </div>" +
            "  <div class='col-12 col-md-6'>" +
            "  <span style='left: 0px'    >  <span style='font-weight: 600;'>" +
            languages.vi.titleThayDoiThongTin +
            ":</span> " +
            languages.vi.contentThayDoiThongTinPhone +
            "</span>" +
            "  </div>" +
            "  </div>" +
            "  </div>" +
            "</div>",
          htmlContentEn:
            "<div class='container-fluid'> " +
            "<div class='row'> " +
            "  <div class='col-12 col-md-6'>" +
            "   <span style='left: 0px' >  <span style='font-weight: 600;'>" +
            languages.en.titleTaoCuaHang +
            ":</span>" +
            languages.en.contentTaoCuaHangPhone +
            "</span >" +
            "  </div>" +
            "  <div class='col-12 col-md-6'>" +
            "  <span style='left: 0px'    >  <span style='font-weight: 600;'>" +
            languages.en.titleThayDoiThongTin +
            ":</span> " +
            languages.en.contentThayDoiThongTinPhone +
            "</span>" +
            "  </div>" +
            "  </div>" +
            "  </div>" +
            "</div>",
          htmlContentCn:
            "<div class='container-fluid'> " +
            "<div class='row'> " +
            "  <div class='col-12 col-md-6'>" +
            "   <span style='left: 0px' >  <span style='font-weight: 600;'>" +
            languages.cn.titleThayDoiThongTin +
            ":</span>" +
            languages.cn.contentThayDoiThongTinPhone +
            "</span >" +
            "  </div>" +
            "  <div class='col-12 col-md-6'>" +
            "  <span style='left: 0px'    >  <span style='font-weight: 600;'>" +
            languages.cn.titleTaoCuaHang +
            ":</span> " +
            languages.cn.contentTaoCuaHangPhone +
            "</span>" +
            "  </div>" +
            "  </div>" +
            "  </div>" +
            "</div>",
        },
        {
          image: langBanner.vi.img6,
          image1:
            "<img src=" +
            langBanner.vi.img7 +
            " class='hover-shadow cursor img-sile'/>",
          imageEn: langBanner.en.img6,
          image1En:
            "<img src=" +
            langBanner.en.img7 +
            " class='hover-shadow cursor img-sile'/>",
          tittle: "",
          htmlContent:
            "<div class='container-fluid'> " +
            "<div class='row'> " +
            "  <div class='col-12 col-md-6'>" +
            "   <span style='left: 0px' >  <span style='font-weight: 600;'>" +
            languages.vi.titleDatHang +
            ": </span>" +
            languages.vi.contentDatHangPhone +
            "</span >" +
            "  </div>" +
            "  <div class='col-12 col-md-6'>" +
            "  <span style='left: 0px'    >  <span style='font-weight: 600;'>" +
            languages.vi.titleThanhToan +
            ":</span>" +
            languages.vi.contentThanhToanPhone +
            "</span>" +
            "  </div>" +
            "  </div>" +
            "  </div>" +
            "</div>",
          htmlContentEn:
            "<div class='container-fluid'> " +
            "<div class='row'> " +
            "  <div class='col-12 col-md-6'>" +
            "   <span style='left: 0px' >  <span style='font-weight: 600;'>" +
            languages.en.titleDatHang +
            ": </span>" +
            languages.en.contentDatHangPhone +
            "</span >" +
            "  </div>" +
            "  <div class='col-12 col-md-6'>" +
            "  <span style='left: 0px'    >  <span style='font-weight: 600;'>" +
            languages.en.titleThanhToan +
            ":</span>" +
            languages.en.contentThanhToanPhone +
            "</span>" +
            "  </div>" +
            "  </div>" +
            "  </div>" +
            "</div>",
          htmlContentCn:
            "<div class='container-fluid'> " +
            "<div class='row'> " +
            "  <div class='col-12 col-md-6'>" +
            "   <span style='left: 0px' >  <span style='font-weight: 600;'>" +
            languages.cn.titleDatHang +
            ": </span>" +
            languages.cn.contentDatHangPhone +
            "</span >" +
            "  </div>" +
            "  <div class='col-12 col-md-6'>" +
            "  <span style='left: 0px'    >  <span style='font-weight: 600;'>" +
            languages.cn.titleThanhToan +
            ":</span>" +
            languages.cn.contentThanhToanPhone +
            "</span>" +
            "  </div>" +
            "  </div>" +
            "  </div>" +
            "</div>",
        },
        {
          image: langBanner.vi.img8,
          image1:
            "<img src=" +
            langBanner.vi.img9 +
            " class='hover-shadow cursor img-sile'/>",
          imageEn: langBanner.en.img8,
          image1En:
            "<img src=" +
            langBanner.en.img9 +
            " class='hover-shadow cursor img-sile'/>",
          tittle: "",
          htmlContent:
            "<div class='container-fluid'> " +
            "<div class='row'> " +
            "  <div class='col-12 col-md-6'>" +
            "   <span style='left: 0px' >  <span style='font-weight: 600;'>" +
            languages.vi.titleSaoKe +
            ": </span>" +
            languages.vi.contentSaoKePhone +
            "</span >" +
            "  </div>" +
            "  <div class='col-12 col-md-6'>" +
            "  <span style='left: 0px'    >  <span style='font-weight: 600;'>" +
            languages.vi.titleTraCuu +
            ":</span>" +
            languages.vi.contentTraCuuPhone +
            "</span>" +
            "  </div>" +
            "  </div>" +
            "  </div>" +
            "</div>",
          htmlContentEn:
            "<div class='container-fluid'> " +
            "<div class='row'> " +
            "  <div class='col-12 col-md-6'>" +
            "   <span style='left: 0px' >  <span style='font-weight: 600;'>" +
            languages.en.titleSaoKe +
            " : </span> " +
            languages.en.contentSaoKePhone +
            "</span >" +
            "  </div>" +
            "  <div class='col-12 col-md-6'>" +
            "  <span style='left: 0px'    >  <span style='font-weight: 600;'>" +
            languages.en.titleTraCuu +
            ":</span>" +
            languages.en.contentTraCuuPhone +
            "</span>" +
            "  </div>" +
            "  </div>" +
            "  </div>" +
            "</div>",
          htmlContentCn:
            "<div class='container-fluid'> " +
            "<div class='row'> " +
            "  <div class='col-12 col-md-6'>" +
            "   <span style='left: 0px' >  <span style='font-weight: 600;'>" +
            languages.cn.titleSaoKe +
            " : </span> " +
            languages.cn.contentSaoKePhone +
            "</span >" +
            "  </div>" +
            "  <div class='col-12 col-md-6'>" +
            "  <span style='left: 0px'    >  <span style='font-weight: 600;'>" +
            languages.cn.titleTraCuu +
            ":</span>" +
            languages.cn.contentTraCuuPhone +
            "</span>" +
            "  </div>" +
            "  </div>" +
            "  </div>" +
            "</div>",
        },
      ],
      autoSlideIntervalID: null,
      savedLanguage: "",
      slidePhone: [],
    };
  },
  created() {
    this.savedLanguage = localStorage.getItem("languages");
    // const lang = this.$route.params.lang;
    if (languages[this.savedLanguage]) {
      this.currentLanguage = languages[this.savedLanguage];
    }

    if (this.savedLanguage && languages[this.savedLanguage]) {
      this.currentLanguage = languages[this.savedLanguage];
    }
  },
  mounted() {
    this.fetchData();

    this.showSlides(this.slideIndex);
    // this.startAutoSlideShow();
  },
  methods: {
    async fetchData() {
      this.apiUrl =
        "https://sheets.googleapis.com/v4/spreadsheets/1EArWilSVZmk23GG5AlCoVov-e1nbVdxbNdNTENzdFwQ/values/jsonSlidePhoneVpos?key=AIzaSyB6KdsUEG02YYtkYDjFlVA-HrSvs_PULh4";

      try {
        const response = await axios.get(this.apiUrl);
        console.log(" this.slideswebtab ", response.data.values);
        this.slidePhone = response.data.values
          .map((row) => {
            return {
              imageVpos: row[0],
              htmlImageVpos: row[1],
              htmlContentVpos1: this.formatText(row[2]),
              htmlContentVpos2:  this.formatText(row[3]),
            };
          })
          .filter((item) => item !== undefined);

        this.$nextTick(() => {
          this.showSlides(0);
        });
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
    formatText(text) {
      const lines = text.split("\n");
      return lines
        .map((line) => {
          const parts = line.split(":");
          if (parts.length === 2) {
            return `<b>${parts[0]}</b> : ${parts[1]} `;
          }
          return line;
        })
        .join("<br>");
    },
  

    startAutoSlideShow() {
      setInterval(() => {
        this.plusSlides(1);
      }, 15000);
    },

    plusSlides(n) {
      this.showSlides((this.slideIndex += n));
    },
    currentSlide(n) {
      this.showSlides((this.slideIndex = n - 1));
    },

    //   showSlides(n) {
    //     let i;
    //     const slides = document.getElementsByClassName("mySlides");
    //     const dots = document.getElementsByClassName("dot");
    //     if (slides.length === 0) {
    //   console.error("No slides found.");
    //   return;
    // }
    //     if (n >= slides.length) {
    //       this.slideIndex = 0;
    //     }
    //     if (n < 0) {
    //       this.slideIndex = slides.length - 1;
    //     }
    //     for (i = 0; i < slides.length; i++) {
    //       slides[i].style.display = "none";
    //     }
    //     for (i = 0; i < dots.length; i++) {
    //       dots[i].className = dots[i].className.replace(" active", "");
    //     }
    //     slides[this.slideIndex].style.display = "block";
    //     // dots[this.slideIndex].className += " active";
    //   },
    showSlides(n) {
      let i;
      const slides = document.getElementsByClassName("mySlides");

      if (slides.length === 0) {
        console.error("No slides found.");
        return;
      }

      if (n >= slides.length) {
        this.slideIndex = 0;
      }
      if (n < 0) {
        this.slideIndex = slides.length - 1;
      }
      for (i = 0; i < slides.length; i++) {
        slides[i].style.display = "none";
      }

      slides[this.slideIndex].style.display = "block";
    },
  },
};
</script>
  <style scoped>
* {
  box-sizing: border-box;
}
body {
  font-family: Verdana, sans-serif;
  margin: 0;
}
.mySlides {
  display: none;
}
img {
  vertical-align: middle;
}

/* Slideshow container */
.slideshow-container {
  max-width: 1000px;
  /* position: relative; */
  margin: auto;
}

/* Next & previous buttons */
.prev,
.next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  padding: 10px;
  /* margin-top: -22px; */
  color: white;
  font-weight: bold;
  margin-right: 50%;
  margin-left: -50%;
  font-size: 18px;
  transition: 0.6s ease;
  border-radius: 0 3px 3px 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  background-color: rgba(57, 148, 238, 0.8);
  border-radius: 10px;
}

/* Position the "next button" to the right */
.next {
  right: 0;
  border-radius: 3px 0 0 3px;
  border-radius: 10px;
}

/* On hover, add a black background color with a little bit see-through */
.prev:hover,
.next:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

/* Caption text */
.text {
  color: #f2f2f2;
  font-size: 15px;
  padding: 8px 12px;
  position: absolute;
  bottom: 8px;
  width: 100%;
  text-align: center;
}

/* Number text (1/3 etc) */
.numbertext {
  color: #f2f2f2;
  font-size: 12px;
  padding: 8px 12px;
  position: absolute;
  top: 0;
}

/* The dots/bullets/indicators */
.dot {
  cursor: pointer;
  height: 15px;
  width: 15px;
  margin: 0 2px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
}

.active,
.dot:hover {
  background-color: #717171;
}

/* Fading animation */

/* On smaller screens, decrease text size */
@media only screen and (max-width: 300px) {
  .prev,
  .next,
  .text {
    font-size: 11px;
  }
}

.max-w-200p {
  width: 190px;
  max-width: 200px;
}

@media (max-width: 1399.98px) {
  .h-check {
    height: auto;
  }
}

@media (min-width: 1400px) {
  .h-check {
    height: 687px;
  }
}
</style>