<template>
  <div class="container-fluid">
    <div class="row" >
      <div class="col-12 col-md-7" >
        <slide-web-tab></slide-web-tab>
      </div>

      <div class="col-12 col-md-5">
        <slide-phone></slide-phone>
      </div>
    </div>
    <!-- <div class="container-fluid d-flex " style="position: fixed; bottom: 0">
        <div class="col-12 col-md-3  p-0 " style="text-align: start;">
          <a class="d-flex align-items-center p-0">
            <i class="fa fa-play-circle-o d-flex color-span" aria-hidden="true"></i>
            <span class="d-flex align-items-end ms-1 color-span"> {{ currentLanguage.huongDanSuDungWeb }}</span >
          </a>
         
        </div>
        <div class="col-12 col-md-3 p-0">
          <a class="d-flex align-items-end color-span" href="https://docs.google.com/document/d/1TGA7zcPFDEqr0JcSZUxadu7EMPuFwgJo/edit" target="_blank" > 
            <span>{{ currentLanguage.hopDongAPI }}</span>
          </a>
        </div>
        <div class="col-12 col-md-3 p-0">
          <a class="d-flex align-items-center p-0"> 
            <i class="fa fa-play-circle-o color-span" aria-hidden="true"></i>
            <span class="align-items-end ms-1 color-span" >{{ currentLanguage.huongDanSuDungPhone }}</span>
          </a>
        </div>
        <div class="col-12 col-md-3 flex-column p-0">
          <a  class="d-flex align-items-end" href="https://drive.google.com/file/d/1peJ4hsHkJnqgoQmwJmZSPbvqALWBQk_K/view?usp=sharing" target="_blank" >
            <span> {{ currentLanguage.CongTyVimass }} </span>
          </a >
          <a class="d-flex align-items-end" href="https://www.sbv.gov.vn/webcenter/portal/vi/menu/trangchu/ttsk/ttsk_chitiet?centerWidth=80%25&dDocName=SBV331391&leftWidth=20%25&rightWidth=0%25&showFooter=false&showHeader=false&_adf.ctrl-state=u3lob0i2z_4&_afrLoop=50020474081672466#%40%3F_afrLoop%3D50020474081672466%26centerWidth%3D80%2525%26dDocName%3DSBV331391%26leftWidth%3D20%2525%26rightWidth%3D0%2525%26showFooter%3Dfalse%26showHeader%3Dfalse%26_adf.ctrl-state%3D2hb8ih4sd_4" target="_blank">
            <span>{{ currentLanguage.giayPhepTGTT }}</span>
          </a>
        </div>
      </div> -->
  </div>
</template>
<script>
import { imageUrls } from "@/components/js/urlImg";
import SlidePhone from "./SlidePhone.vue";
import SlideWebTab from "./SlideWebTab.vue";
import { languages } from "@/components/js/langVpos";
export default {
  components: { SlidePhone, SlideWebTab },
  data() {
    return {
      currentLanguage: languages.vi,

      imageUrls: imageUrls,
      slideIndex: 0,
      slides: [
        {
          image: imageUrls.img1,
          tittle: "Đăng ký",
          content:
            "Nhập số điện thoại/email <br> Mật khẩu đăng ký và mật khẩu token <br>  để đăng ký tài khoản",
          htmlContent:
            " <h3 style='color: #3b3636 !important'>Trang chủ</h3>" +
            "   <span style='left: 0px' >Nhập số điện thoại/email <br> Mật khẩu đăng ký và mật khẩu token <br> để đăng ký tài khoản</span >" +
            "  <br />" +
            "  <h3 style='color: #3b3636 !important'>Đăng nhập</h3>" +
            "  <span style='left: 0px'    >Nhập số điện thoại/email <br> và mật khẩu hoặc face ID để đăng nhập</span>",
        },
        {
          image: imageUrls.img2,
          tittle: "",
          htmlContent:
            " <h3 style='color: #3b3636 !important'>Đăng ký</h3>" +
            "   <span style='left: 0px' >Thông tin tài khoản <br> Danh sách cửa hàng <br>Sản phẩm<br> Thay đổi thông tin<br> Sao kê <br> Tra cứu <br>Lưu/Xóa thẻ</span >",
        },
        {
          image: imageUrls.img3,
          tittle: "",
          htmlContent:
            " <h3 style='color: #3b3636 !important'>Tạo cửa hàng</h3>" +
            "   <span style='left: 0px' >Tên cửa hàng, địa chỉ,<br> tọa độ địa điểm, ảnh chụp cửa hàng, <br>Tài khoản ngân hàng nhận tiền, <br>Hạn mức rút tiền về tài khoản</span >" +
            "  <br />" +
            "  <h3 style='color: #3b3636 !important'>Thay đổi thông tin</h3>" +
            "  <span style='left: 0px'    >Thêm, sửa xóa thông tin,<br>  danh mục, sản phẩm</span>" +
            "  <br />" +
            "  <h3 style='color: #3b3636 !important'>Khuyến mại</h3>" +
            "  <span style='left: 0px'    >Đổi điểm thưởng, ưu đãi, đổi quà</span>",
        },
        {
          image: imageUrls.img4,
          tittle: "",
          htmlContent:
            " <h3 style='color: #3b3636 !important'>Bán hàng</h3>" +
            "   <span style='left: 0px' >Hiển thị toàn bộ dịch vụ cửa hàng<br> Chọn sản phẩm, dịch vụ, <br>số lượng để tạo đơn hàng</span >",
        },
        {
          image: imageUrls.img5,
          tittle: "",
          htmlContent:
            " <h3 style='color: #3b3636 !important'>Đặt hàng</h3>" +
            "   <span style='left: 0px' >Xác nhận đơn hàng, nhập mã số thuế, email,<br> điện thoại để nhận hóa đơn</span >",
        },
        {
          image: imageUrls.img6,
          tittle: "",
          htmlContent:
            " <h3 style='color: #3b3636 !important'>QR động</h3>" +
            "   <span style='left: 0px' >Sinh VietQR động cho theo chuẩn NHNN theo từng bàn</span >" +
            "  <br />" +
            "  <h3 style='color: #3b3636 !important'>Thanh toán</h3>" +
            "  <span style='left: 0px'    >Tất cả ứng dụng ngân hàng, ví điện tử <br> đều có thể quét QR thanh toán hoặc <br> đứng trước camera xác thực mặt để <br>thanh toán bằng thẻ Vimass <br> <br>Hỗ trợ thanh toán chuyển đổi ngoại tệ <br>(USD, EUR, CNY, JPY, KRW)</span>",
        },
        {
          image: imageUrls.img7,
          tittle: "",
          htmlContent:
            " <h3 style='color: #3b3636 !important'>Sao kê</h3>" +
            "   <span style='left: 0px' >Sao kê toàn bộ giao dịch nhận<br> tiền theo từng bàn, <br>từng cửa hàng từ ngày X đến ngày Y</span >",
        },
        {
          image: imageUrls.img8,
          tittle: "",
          htmlContent:
            " <h3 style='color: #3b3636 !important'>Tra cứu</h3>" +
            "   <span style='left: 0px' >(Dành cho người có thẩm quyền)</span >" +
            "  <br />  <br />" +
            "   <span style='left: 0px'><span style='font-weight: 600;'>Cửa hàng:</span>  thông kê giao dịch và tiền nhận <br> theo từng cửa hàng</span >" +
            "  <br />  <br />" +
            "   <span style='left: 0px'><span style='font-weight: 600;'>Tiền về tài khoản: </span>thống kê giao dịch chuyển tiền tự động về tài khoản ngân hàng</span >" +
            "  <br />  <br />" +
            "   <span style='left: 0px'><span style='font-weight: 600;'>Tiền về tài khoản: </span> thống kê giao dịch, <br> tiền nhận về tài khoản,<br> phí theo ngày, tháng, năm</span >",
        },
      ],
      autoSlideIntervalID: null,
    };
  },
  created() {
    this.savedLanguage = localStorage.getItem("languages");
    // const lang = this.$route.params.lang;
    if (languages[this.savedLanguage]) {
      this.currentLanguage = languages[this.savedLanguage];
    }

    if (this.savedLanguage && languages[this.savedLanguage]) {
      this.currentLanguage = languages[this.savedLanguage];
    }
  },
  mounted() {
    // this.startAutoSlideShow();
  },
  methods: {
    startAutoSlideShow() {
      setInterval(() => {
        this.plusSlides(1);
      }, 15000);
    },

    plusSlides(n) {
      this.showSlides((this.slideIndex += n));
    },
    currentSlide(n) {
      this.showSlides((this.slideIndex = n - 1));
    },

    
  },
};
</script>
<style scoped>
* {
  box-sizing: border-box;
}
body {
  font-family: Verdana, sans-serif;
  margin: 0;
}
.mySlides {
  display: none;
}
img {
  vertical-align: middle;
}

/* Slideshow container */
.slideshow-container {
  max-width: 1000px;
  position: relative;
  margin: auto;
}

/* Next & previous buttons */
.prev,
.next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  padding: 10px;
  /* margin-top: -22px; */
  color: white;
  font-weight: bold;
  margin-right: 50%;
  margin-left: -50%;
  font-size: 18px;
  transition: 0.6s ease;
  border-radius: 0 3px 3px 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  background-color: rgba(57, 148, 238, 0.8);
  border-radius: 10px;
}

/* Position the "next button" to the right */
.next {
  right: 0;
  border-radius: 3px 0 0 3px;
  border-radius: 10px;
}

/* On hover, add a black background color with a little bit see-through */
.prev:hover,
.next:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

/* Caption text */
.text {
  color: #f2f2f2;
  font-size: 15px;
  padding: 8px 12px;
  position: absolute;
  bottom: 8px;
  width: 100%;
  text-align: center;
}

/* Number text (1/3 etc) */
.numbertext {
  color: #f2f2f2;
  font-size: 12px;
  padding: 8px 12px;
  position: absolute;
  top: 0;
}

/* The dots/bullets/indicators */
.dot {
  cursor: pointer;
  height: 15px;
  width: 15px;
  margin: 0 2px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
}

.active,
.dot:hover {
  background-color: #717171;
}

/* Fading animation */

/* On smaller screens, decrease text size */
@media only screen and (max-width: 300px) {
  .prev,
  .next,
  .text {
    font-size: 11px;
  }
}
.style-font{
  font-size: 14px; line-height: 3;
   /* margin-left: 10px; */
    color: rgb(20, 129, 218) !important; font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
 .color-span{
  color: rgb(20, 129, 218)
 }    

</style>