//
//                _ooOoo_                                     NAM MÔ A DI ĐÀ PHẬT !
//               o8888888o
//               88" . "88                              Thí chủ con tên là Chử Văn Viên
//               (| -_- |)                            dương lịch ngày 8 tháng 10 năm 1999
//                O\ = /O
//            ____/`---'\____                  Con lạy chín phương trời, con lạy mười phương đất
//            .' \\| |// `.                       Chư Phật mười phương, mười phương chư Phật
//           / \\||| : |||// \                    Con ơn nhờ Trời đất chổ che, Thánh Thần cứu độ
//         / _||||| -:- |||||- \             Xin nhất tâm kính lễ Hoàng thiên Hậu thổ, Tiên Phật Thánh Thần
//           | | \\\ - /// | |                           Giúp đỡ con code sạch ít bug
//         | \_| ''\---/'' | | |                    Biểu diễn ko lỗi, sếp quý tăng lương
//         \ .-\__ `-` ___/-. /                       Sức khoẻ dồi dào, tiền vào như nước
//       ___`. .' /--.--\ `. . __
//    ."" '< `.___\_<|>_/___.' >'"".         NAM MÔ VIÊN THÔNG GIÁO CHỦ ĐẠI TỪ ĐẠI BI TẦM THANH CỨU KHỔ CỨU NẠN
//   | | : `- \`.;`\ _ /`;.`/ - ` : | |              QUẢNG ĐẠI LINH CẢM QUÁN THẾ ÂM BỒ TÁT
//     \ \ `-. \_ __\ /__ _/ .-` / /
//======`-.____`-.___\_____/___.-`____.-'======
//                `=---='
//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^
//
 var ip = "http://124.158.4.173:8080";
// var ipQuyen = "http://210.245.8.6:8080";
var ipDpTu = "http://192.168.1.195:58080";
// var ipVpos = "http://103.21.150.9:8080";
// var domainWebVimass = "https://web.vimass.vn";
var domain = "https://vimass.vn";

export const apiDangNhap = domain + "/vmbank/services/account/loginVer2";
export const apiDangKy = ip+"/vmbank/services/account/createNewAcc2"
export const apiTaoTin = "/autobank/services/vimassTool/";
export const apiTestDanh = "http://192.168.1.199:58080/autobank/services/vimassTool/dieuPhoi"
export const apiTestTu = ipDpTu + "/autobank/services/vimassTool/dieuPhoi";
export const apiDayFile = ipDpTu + "/autobank/services/vimassTool/reviceExcel/";
export const apiGetFile = ipDpTu + "/autobank/services/vimassTool/file/";

// var domainWebVimass = "https://web.vimass.vn";
export const apiVpos = domain + "/VMServices/services/Vpos/requestCommand"
export const apiCheckLogin = domain + "/vmNoiBo/services/account/checkVar?pass=8249539tgsdlka&param="
export const apiLayTiGia = domain + "/vimass/services/VMTool/currency"
export const apiSearchBank = domain + "/VMServices/services/VimassCache/traCuuTKNH"
export const apiUploadImg = domain + "/VimassMedia/services/VMMedia/uploadImg"




// export const apiLayTiGia = "http://210.245.8.7:12318/vimass/services/VMTool/currency"
export const apiRegisterByEmail =domain + "/VMServices/services/Vpos/requestCommand";
export const SERVICE_ROOT  = "https://vimass.vn/vmbank/services/";
export const apiComfrimPhoneOTP = SERVICE_ROOT+"vmbank/services/account/confirmOTPToCreateNewAcc2";
export const apiChangePassPhone = domain + "/vmbank/services/auth/forgetPassSoftToken";
export const apiComfrimChangePassPhone = domain + "/vmbank/services/auth/confirmOTPForgetPassSoftToken";
// var domain_vpos = "http://103.21.150.9:8080";
export const apiCategory = domain + "/VMServices/services/Vpos/requestCommand";


