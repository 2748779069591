<template>
  <div class="d-flex justify-content-between">
    <a @click="plusSlidesWebTab(-1)" style="font-size: x-large">❮</a>
    <div class="d-flex justify-content-center align-items-center">
      <a
        class="d-flex me-1 span-style span-color--blue pointer"
        href="https://pos.vimass.vn/"
        target="_blank"
        >{{ currentLanguage.title_link_pos }}</a
      >
      <span class="d-flex span-style span-color--black">
        {{ currentLanguage.title_trenmaytinh }}</span
      >
    </div>
    <a @click="plusSlidesWebTab(1)" style="font-size: x-large">❯</a>
  </div>
  <div class="slideswebtabhow-container">
    <div
      v-for="(slide, index) in slidesWebTab"
      :key="index"
      class="mySlidesWebTab container-fluid"
    >
      <div class="row p-0">
        <div
          class="column col-12 p-0 pt-2 pb-3 mt-5"
          v-if="savedLanguage === 'vi' || !savedLanguage"
        >
          <img :src="slide.imageVpos" class="hover-shadow cursor img-sile" />
        </div>
        <div
          class="column col-12 p-0 pt-2 pb-3 mt-5"
          v-if="savedLanguage == 'en' || savedLanguage == 'cn'"
        >
          <img :src="slide.imageVpos" class="hover-shadow cursor img-sile" />
        </div>
        <div class="column col-12 m-auto p-0">
          <!-- <div class="container-fluid" style="height: 110px">
             -->
          <div class="container-fluid p-0 mt-3">
            <div v-if="savedLanguage === 'vi' || !savedLanguage">
              <div class="d-flex flex-column mt-3 style-line">
                <!-- <textarea  v-html="slide.htmlContentVpos" style="border: none; height: 200px; background: white; " disabled></textarea>  -->
                <span v-html="slide.htmlContentVpos"> </span>
              </div>
            </div>
            <div v-else-if="savedLanguage === 'en'">
              <div
                v-html="slide.htmlContentVpos"
                class="d-flex flex-column mt-3 style-line"
              ></div>
            </div>
            <div v-else-if="savedLanguage === 'cn'">
              <div
                v-html="slide.htmlContentVpos"
                class="d-flex flex-column mt-3 style-line"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
    <script>
import { imageUrls, langBanner } from "@/components/js/urlImg";

import { languages } from "@/components/js/langVpos";
import axios from "axios";
export default {
  data() {
    return {
      savedLanguage: "",
      currentLanguage: languages.vi,
      imageUrls: imageUrls,
      langBanner: langBanner.vi,
      slideIndex: 0,

      slidesWebTab: [],
      autoSlideIntervalID: null,
    };
  },
  created() {
    this.savedLanguage = localStorage.getItem("languages");
    // const lang = this.$route.params.lang;
    if (languages[this.savedLanguage]) {
      this.currentLanguage = languages[this.savedLanguage];
    }

    if (this.savedLanguage && languages[this.savedLanguage]) {
      this.currentLanguage = languages[this.savedLanguage];
    }
  },
  mounted() {
    this.fetchData();
    this.showSlidesWebTab(0);
  },
  methods: {
    async fetchData() {
      this.apiUrl =
        "https://sheets.googleapis.com/v4/spreadsheets/1EArWilSVZmk23GG5AlCoVov-e1nbVdxbNdNTENzdFwQ/values/jsonSlideWebVpos?key=AIzaSyB6KdsUEG02YYtkYDjFlVA-HrSvs_PULh4";

      try {
        const response = await axios.get(this.apiUrl);
        console.log(" this.slideswebtab ", response.data.values);
        this.slidesWebTab = response.data.values
          .map((row) => {
            return {
              imageVpos: row[0],
              htmlContentVpos: this.formatText(row[1]),
            };
          })
          .filter((item) => item !== undefined);

        this.$nextTick(() => {
          this.showSlidesWebTab(0);
        });
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
    formatText(text) {
      const lines = text.split("\n");
      return lines
        .map((line) => {
          const parts = line.split(":");
          if (parts.length === 2) {
            return `<b>${parts[0]}</b> : ${parts[1]} `;
          }
          return line;
        })
        .join("<br>");
    },
    startAutoSlidesWebTabhow() {
      setInterval(() => {
        this.plusSlidesWebTab(1);
      }, 15000);
    },

    plusSlidesWebTab(n) {
      console.log("index " + n);
      this.showSlidesWebTab((this.slideIndex += n));
    },
    currentSlide(n) {
      this.showSlidesWebTab((this.slideIndex = n - 1));
    },

    showSlidesWebTab(n) {
      let i;
      const slides = document.getElementsByClassName("mySlidesWebTab");

      if (slides.length === 0) {
        console.error("No slides found.");
        return;
      }

      if (n >= slides.length) {
        this.slideIndex = 0;
      }
      if (n < 0) {
        this.slideIndex = slides.length - 1;
      }
      for (i = 0; i < slides.length; i++) {
        slides[i].style.display = "none";
      }

      slides[this.slideIndex].style.display = "block";
    },
  },
};
</script>
    <style scoped>
* {
  box-sizing: border-box;
}
body {
  font-family: Verdana, sans-serif;
  margin: 0;
}
.mySlidesWebTab {
  display: none;
}
img {
  vertical-align: middle;
}

/* SlidesWebTabhow container */
.slideswebtabhow-container {
  max-width: 1110px;
  /* position: relative; */
  margin: auto;
}

/* Next & previous buttons */
.prev,
.next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  padding: 10px;
  /* margin-top: -22px; */
  color: white;
  font-weight: bold;
  margin-right: 50%;
  margin-left: -50%;
  font-size: 18px;
  transition: 0.6s ease;
  border-radius: 0 3px 3px 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  background-color: rgba(57, 148, 238, 0.8);
  border-radius: 10px;
}

/* Position the "next button" to the right */
.next {
  right: 0;
  border-radius: 3px 0 0 3px;
  border-radius: 10px;
}

/* On hover, add a black background color with a little bit see-through */
.prev:hover,
.next:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

/* Caption text */
.text {
  color: #f2f2f2;
  font-size: 15px;
  padding: 8px 12px;
  position: absolute;
  bottom: 8px;
  width: 100%;
  text-align: center;
}

/* Number text (1/3 etc) */
.numbertext {
  color: #f2f2f2;
  font-size: 12px;
  padding: 8px 12px;
  position: absolute;
  top: 0;
}

/* The dots/bullets/indicators */
.dot {
  cursor: pointer;
  height: 15px;
  width: 15px;
  margin: 0 2px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
}

.active,
.dot:hover {
  background-color: #717171;
}

/* Fading animation */

/* On smaller screens, decrease text size */
@media only screen and (max-width: 300px) {
  .prev,
  .next,
  .text {
    font-size: 11px;
  }
}
</style>