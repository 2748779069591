<template>
  <header class="d-flex justify-content-between align-items-center" style="background-color: white;">
    <label for="toggle-1" class="toggle-menu" @click="toggleMenu"
      ><ul>
        <li style="background-color: #000;"></li>
        <li style="background-color: #000;"></li>
        <li style="background-color: #000;"></li>
        </ul
    ></label>
    <!-- <strong @click="toggleMenu"> &#9776;	</strong> -->
    <nav
      class="nav_center"
      style="padding: 0"
      :style="{ display: isMenuVisible ? 'block' : 'none' }"
    >
      <ul style="list-style: none">
          <li style="border-right: 1px solid rgb(167 167 167);"
          :class="{ topmenuactive: activeTab === 'gioiThieu' }"
          @click="changeTab('gioiThieu')"
        >
          <a id="urlGioithieu">{{
            currentLanguage.header_text_gioiThieu
          }}</a>
        </li>
           <li style="border-right: 1px solid rgb(167 167 167);"
          :class="{ topmenuactive: activeTab === 'tieuhoc' }"
          @click="changeTab('tieuhoc')"
        >
          <a id="urlBai1">{{
            currentLanguage.header_text_theDaNang_mamNonTieuHoc
          }}</a>
        </li>
           <li style="border-right: 1px solid rgb(167 167 167);"
          :class="{ topmenuactive: activeTab === 'thcs' }"
          @click="changeTab('thcs')"
        >
          <a >{{
            currentLanguage.header_text_theDaNang_thcsThpt
          }}</a>
        </li>
          <li style="border-right: 1px solid rgb(167 167 167);"
          :class="{ topmenuactive: activeTab === 'thpt' }"
          @click="changeTab('thpt')"
        >
          <a >{{
            currentLanguage.header_text_theDaNang_Thpt
          }}</a>
        </li>
           <li style="border-right: 1px solid rgb(167 167 167);"
          :class="{ topmenuactive: activeTab === 'caodang' }"
          @click="changeTab('caodang')"
        >
          <a 
            >{{ currentLanguage.header_text_theDaNang_daiHocCaoDang }}
          </a>
        </li>
           <li style="border-right: 1px solid rgb(167 167 167);"
          :class="{ topmenuactive: activeTab === 'daihoc' }"
          @click="changeTab('daihoc')"
        >
          <a>{{
            currentLanguage.header_text_theDaNang_daiHoc
          }}</a>
        </li>
           <li style="border-right: 1px solid rgb(167 167 167);"
          :class="{ topmenuactive: activeTab === 'lienhe' }"
          @click="changeTab('lienhe')"
        >
          <a >{{
            currentLanguage.header_text_lienHe
          }}</a>
        </li>
      </ul>
    </nav>
    
  </header>
  <div class="wrapper row3">
    <main class="hoc container-fluid clear bv">
    
          <!-- gioi thieu -->
          <div
            id="gioiThieu"
            v-show="showGioiThieu"
            style="min-height: 500px"
            class="padding-top15"
          >
            <div class="title-bai-viet" v-html="titleGioiThieu"></div>
            <div v-html="contentGioiThieu"></div>
          </div>
          <!-- tieu hoc-->
          <div
            id="tieuhoc"
            v-show="showTieuHoc"
            style="min-height: 500px; background-image: none !important"
            class="padding-top15"
          >
            <div class="title-bai-viet" v-html="titleTieuHoc"></div>
            <div v-html="contentTieuHoc"></div>
          </div>
          <!-- thcs -->
          <div
            id="thcs"
            v-show="showTHCS"
            style="min-height: 500px; background-image: none !important"
            class="padding-top15"
          >
            <div class="title-bai-viet" v-html="titleTHCS"></div>
            <div v-html="contentTHCS"></div>
          </div>
          <!-- THPT -->
          <div
            id="suckhoe"
            v-show="showTHPT"
            style="min-height: 500px; background-image: none !important"
            class="padding-top15"
          >
            <div class="title-bai-viet" v-html="titleTHPT"></div>
            <div v-html="contentTHPT"></div>
          </div>
          <!-- CAO DANG -->
          <div
            id="caodang"
            v-show="showCaoDang"
            style="min-height: 500px; background-image: none !important"
            class="padding-top15"
          >
            <div class="title-bai-viet" v-html="titleCaoDang"></div>
            <div v-html="contentCaoDang"></div>
          </div>
          <!-- dai hoc -->
          <div
            id="daihoc"
            v-show="showDaiHoc"
            style="min-height: 500px; background-image: none !important"
          >
            <div class="title-bai-viet" v-html="titleDaiHoc"></div>
            <div v-html="contentDaiHoc"></div>
          </div>
          <!-- LIEN HE -->
          <div
            id="lienhe"
            v-show="showLienHe"
            style="min-height: 500px; background-image: none !important"
          >
            <div class="title-bai-viet" v-html="titleLienHe"></div>
            <div v-html="contentLienHe"></div>
          </div>
        
    </main>
  </div>
  <!-- <FooterView></FooterView> -->
</template>
  <script>
// import FooterView from "@/views/Vimass/Footer/FooterView.vue";
import { languages } from "@/components/languages";
import { apiChiTietBaiViet } from "@/components/listService";
import axios from "axios";

export default {
  components: {
    // FooterView,
  },
  data() {
    return {
      activeTab: "gioiThieu",
      isMenuVisible: false,

      currentLanguage: languages.vi,
      lang: "",
      base64: "",
      base64Title: "",

      //show
      showGioiThieu: true,
      showTieuHoc: false,
      showTHCS: false,
      showTHPT: false,
      showCaoDang: false,
      showDaiHoc: false,
      showLienHe: false,

      //active tab
      isActiveGioiThieu: true,
      isActiveTieuHoc: false,
      isActiveTHCS: false,
      isAcitveTHPT: false,
      isActiveCaoDang: false,
      isActiveDaiHoc: false,
      isActiveLienHe: false,

      //title
      titleGioiThieu: "",
      titleTieuHoc: "",
      titleTHCS: "",
      titleTHPT: "",
      titleCaoDang: "",
      titleDaiHoc: "",
      titleLienHe: "",

      //content
      contentGioiThieu: "",
      contentTieuHoc: "",
      contentTHCS: "",
      contentTHPT: "",
      contentCaoDang: "",
      contentDaiHoc: "",
      contentLienHe: "",
      hasMounted: false,
    };
  },
  mounted() {
    if (window.innerWidth <= 768) {
      this.isMenuVisible = false;
    } else {
      this.isMenuVisible = true;
    }
    if (!this.hasMounted) {
      this.hasMounted = true; // Set the flag to true to indicate that mounted has been executed
      this.lang = localStorage.getItem("languages");
      if (this.lang == "vi") {
        document.title = "Vimass.vn - Giáo dục";
      } else if (this.lang == "en") {
        document.title = "Vimass.vn - Education";
      } else if (this.lang == "cn") {
        document.title = "Vimass.vn - 教育";
      } else if (this.lang == "kr") {
        document.title = "Vimass.vn - 교육";
      }
      this.changeTab("gioiThieu");
    }
  },
  created() {
    const savedLanguage = localStorage.getItem("languages");
    if (savedLanguage && languages[savedLanguage]) {
      this.currentLanguage = languages[savedLanguage];
    }
  },
  methods: {
    changeLanguage(lang) {
      this.currentLanguage = languages[lang];
      localStorage.setItem("languages", lang);
      window.location.reload();
    },

    async performPostRequest(id, section) {
      this.lang = localStorage.getItem("languages");

      try {
        // Thực hiện POST request
        const response = await axios.post(apiChiTietBaiViet, {
          id: id,
          langId: "0",
        });
        if (this.lang === "vi") {
          this.base64 = response.data.result.content_vi;
          this.base64Title = response.data.result.title_vi;
        } else if (this.lang === "en") {
          this.base64 = response.data.result.content_en;
          this.base64Title = response.data.result.title_en;
        } else if (this.lang === "cn") {
          this.base64 = response.data.result.content_cn;
          this.base64Title = response.data.result.title_cn;
        }else if (this.lang === "kr") {
          this.base64 = response.data.result.content_kr;
          this.base64Title = response.data.result.title_kr;
        }else{
          this.base64 = response.data.result.content_vi;
          this.base64Title = response.data.result.title_vi;
        }

        var title = atob(this.base64Title);
        var decodedString = atob(this.base64);

        // Store content separately for each section
        if (section === "gioiThieu") {
          this.titleGioiThieu = decodeURIComponent(escape(title));
          this.contentGioiThieu = decodeURIComponent(escape(decodedString));
        } else if (section === "tieuhoc") {
          this.titleTieuHoc = decodeURIComponent(escape(title));
          this.contentTieuHoc = decodeURIComponent(escape(decodedString));
        } else if (section === "thcs") {
          this.titleTHCS = decodeURIComponent(escape(title));
          this.contentTHCS = decodeURIComponent(escape(decodedString));
        } else if (section === "thpt") {
          this.titleTHPT = decodeURIComponent(escape(title));
          this.contentTHPT = decodeURIComponent(escape(decodedString));
        } else if (section === "caodang") {
          this.titleCaoDang = decodeURIComponent(escape(title));
          this.contentCaoDang = decodeURIComponent(escape(decodedString));
        } else if (section === "daihoc") {
          this.titleDaiHoc = decodeURIComponent(escape(title));
          this.contentDaiHoc = decodeURIComponent(escape(decodedString));
        } else if (section === "lienhe") {
          this.titleLienHe = decodeURIComponent(escape(title));
          this.contentLienHe = decodeURIComponent(escape(decodedString));
        }
      } catch (error) {
        console.error("Error:", error);
      }
    },
    toggleMenu() {
      this.isMenuVisible = !this.isMenuVisible;
    },
    changeTab(tab) {
      if (window.innerWidth <= 768) {
        this.isMenuVisible = false;
      }
      this.lang = localStorage.getItem("languages");

      if (tab === "gioiThieu") {
        this.isActiveGioiThieu = true;
        this.isActiveTieuHoc = false;
        this.isActiveTHCS = false;
        this.isAcitveTHPT = false;
        this.isActiveCaoDang = false;
        this.isActiveDaiHoc = false;
        this.isActiveLienHe = false;
        // this.performPostRequest("1694406782839x1e10", 'gioiThieu', this.lang); kha nang id loi
        this.performPostRequest("1694406782839x1e10", "gioiThieu", this.lang);
      } else if (tab === "tieuhoc") {
        this.isActiveGioiThieu = false;
        this.isActiveTieuHoc = true;
        this.isActiveTHCS = false;
        this.isAcitveTHPT = false;
        this.isActiveCaoDang = false;
        this.isActiveDaiHoc = false;
        this.isActiveLienHe = false;
        this.performPostRequest("1693973335517jxry9", "tieuhoc", this.lang);
      } else if (tab === "thcs") {
        this.isActiveGioiThieu = false;
        this.isActiveTieuHoc = false;
        this.isActiveTHCS = true;
        this.isAcitveTHPT = false;
        this.isActiveCaoDang = false;
        this.isActiveDaiHoc = false;
        this.isActiveLienHe = false;
        this.performPostRequest("1693993651381zumxd", "thcs", this.lang);
      } else if (tab === "thpt") {
        this.isActiveGioiThieu = false;
        this.isActiveTieuHoc = false;
        this.isActiveTHCS = false;
        this.isAcitveTHPT = true;
        this.isActiveCaoDang = false;
        this.isActiveDaiHoc = false;
        this.isActiveLienHe = false;
        this.performPostRequest("1693973462420zm2fo", "thpt", this.lang);
      } else if (tab === "caodang") {
        this.isActiveGioiThieu = false;
        this.isActiveTieuHoc = false;
        this.isActiveTHCS = false;
        this.isAcitveTHPT = false;
        this.isActiveCaoDang = true;
        this.isActiveDaiHoc = false;
        this.isActiveLienHe = false;
        this.performPostRequest("16945770557827dnkj", "caodang", this.lang);
      } else if (tab === "daihoc") {
        this.isActiveGioiThieu = false;
        this.isActiveTieuHoc = false;
        this.isActiveTHCS = false;
        this.isAcitveTHPT = false;
        this.isActiveCaoDang = false;
        this.isActiveDaiHoc = true;
        this.isActiveLienHe = false;
        this.performPostRequest("1693973557639bchbw", "daihoc", this.lang);
      } else if (tab === "lienhe") {
        this.isActiveGioiThieu = false;
        this.isActiveTieuHoc = false;
        this.isActiveTHCS = false;
        this.isAcitveTHPT = false;
        this.isActiveCaoDang = false;
        this.isActiveDaiHoc = false;
        this.isActiveLienHe = true;
        this.performPostRequest("1690272053288yg3c7", "lienhe", this.lang);
      }

      this.activeTab = tab; // Cập nhật giá trị activeTab

      this.showGioiThieu = tab === "gioiThieu";
      this.showTieuHoc = tab === "tieuhoc";
      this.showTHCS = tab === "thcs";
      this.showTHPT = tab === "thpt";
      this.showCaoDang = tab === "caodang";
      this.showDaiHoc = tab == "daihoc";
      this.showLienHe = tab == "lienhe";
    },
  },
};
</script>
  
  